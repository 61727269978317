import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery } from '@apollo/client';
import ActivityCard from './ActivityCard';
import SearchBar from '../SearchBar';
import db from '../../scripts/db';
import ActivityTypeFilter from './ActivityTypeFilter';
import { allActivities, myActivities, favoriteActivities } from './gqlQueries';
import { activityTypes } from '../../scripts/helpers';

const activitiesPerPage = 10;

function displayActivities(activity) {
    const favoritesId = activity?.ocgTFavoritesByActivityId?.nodes?.[0]?.id;
    return <ActivityCard key={activity.id} activity={activity} favoritesId={favoritesId} />;
}

function getQuery(pathname, searchText, searchTextArr, activityType, clinician, offset) {
    if (pathname === '/activities/self') {
        return myActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    } else if (pathname === '/activities/favorites') {
        return favoriteActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    } else {
        return allActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    }
}

export default function Activities() {
    const endOfScrollRef = React.useRef(null);
    const observer = React.useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [pathname, setPathname] = React.useState(location.pathname);
    const [searchText, setSearchText] = React.useState("");
    const [searchTextArr, setSearchTextArr] = React.useState([]);
    const [activityType, setActivityType] = React.useState(0);
    const [activities, setActivities] = React.useState([]);
    const changeSearchText = (newValue) => {
        setOffset(0);
        setActivities([]);
        setSearchText(newValue);
    }
    const changeSearchTextArr = (newValue) => {
        setOffset(0);
        setActivities([]);
        setSearchTextArr(newValue);
    }
    const changeActivityType = (e, newValue) => {
        setOffset(0);
        setActivities([]);
        setActivityType(newValue);
    }
    const [offset, setOffset] = React.useState(0);
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const query = getQuery(pathname, searchText, searchTextArr, activityType, clinician, offset);
    const { loading, error, data } = useQuery(query, {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const gqlActivities = data?.allOcgTActivities;
    const moreActivitiesToLoad = gqlActivities?.totalCount > (offset + activitiesPerPage);

    const intersectionCallback = React.useCallback((entries) => {
        const lastEntry = entries[entries.length - 1];
        if (lastEntry.isIntersecting && moreActivitiesToLoad) {
            setOffset(offset + activitiesPerPage);
        }
    }, [offset, moreActivitiesToLoad ]);

    React.useEffect(() => {
        // enter when adding to search text array
        if (data?.allOcgTActivities?.nodes && searchTextArr.length > 0) {
            setActivities(data?.allOcgTActivities?.nodes);
        }
        // enter all other time and when scrolling
        else if (data?.allOcgTActivities?.nodes) {
            setActivities(prevActivities => [...prevActivities, ...data?.allOcgTActivities?.nodes]);
        }
    }, [searchTextArr, data]);
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [error, navigate]);
    React.useEffect(() => {
        // Create IntersectionObserver
        observer.current = new IntersectionObserver(intersectionCallback, { root: null, rootMargin: "0px", threshold: 0.5 });
        observer.current.observe(endOfScrollRef.current);
 
        // Cleanup
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [intersectionCallback]);
    React.useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);
    React.useEffect(() => {
        let title = 'Public Activity Library';
    
        if (pathname === '/activities/self') {
            title = 'My Activities';
        } else if (pathname === '/activities/favorites') {
            title = 'My Favorites';
        }
    
        db.appbar.put({ key: 1, title: title });

        setOffset(0);
        setActivities([]);
    }, [pathname]);
    return (
        <React.Fragment>
            <ActivityTypeFilter activityType={activityType} changeActivityType={changeActivityType} />
            <div style={{display:'flex'}}>
                <SearchBar searchText={searchText} setSearchText={changeSearchText} setSearchTextArr={changeSearchTextArr} 
                    activityType={activityTypes[activityType]} 
                    clinician_id={pathname === '/activities/self' ? clinician?.id : null} 
                    published={pathname === '/activities' ? true : null} 
                    fav={pathname === '/activities/favorites' ? true : null} />
            </div>
            <div style={{ overflow:'scroll' }}>
                {activities?.map(activity => displayActivities(activity))}
                <div ref={endOfScrollRef} style={{textAlign: 'center', marginTop: '0.5rem'}}>
                    { loading || moreActivitiesToLoad ? 'Loading...' : '' }
                </div>
            </div>
        </React.Fragment>
    )
}