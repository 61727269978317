import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { blue } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SideNav from './components/SideNav';
import AppBar from './components/AppBar';
import Activities from './components/Activities/Activities';
import ActivityForm from './components/Activities/ActivityForm';
import ActivityDetails from './components/Activities/ActivityDetails';
import Patients from './components/Patients/Patients';
import PatientDetails from './components/Patients/PatientDetails';
import PatientResponses from './components/Patients/PatientResponses';
import PatientNew from './components/Patients/PatientNew';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Steps from './components/Steps/Steps';

const domain = (process.env.NODE_ENV === 'production') ? '' : 'http://localhost:4000';

const drawerWidth = 240;

const theme = createTheme({
    palette: {
        primary: {
            main: blue[500],
        },
    },
    components: {
        // Name of the component
        MuiDrawer: {
            defaultProps: {
            // The props to change the default for.
                PaperProps: true, // No more ripple, on the whole application 💣!
            },
        },
    },
})

// const theme = createTheme({
//     palette: {
//       primary: blue,
//     },
//     root: {
//         display: 'flex',
//       },
//       appBar: {
//         width: `calc(100% - ${drawerWidth}px)`,
//         marginLeft: drawerWidth,
//       },
//       drawer: {
//         width: drawerWidth,
//         flexShrink: 0,
//       },
//       drawerPaper: {
//         width: drawerWidth,
//         backgroundColor: '#18202c',
//         color: 'rgba(255, 255, 255, 0.7)'
//       },
//       selected: {
//         color: lightBlue[500],
//       },
//       // necessary for content to be below app bar
//       toolbar: theme.mixins.toolbar,
//       content: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.background.default,
//         padding: theme.spacing(3),
//       },
//       // navigator styles
//       categoryHeader: {
//         paddingTop: theme.spacing(2),
//         paddingBottom: theme.spacing(2),
//       },
//       categoryHeaderPrimary: {
//         color: theme.palette.common.white,
//       },
//       item: {
//         paddingTop: 1,
//         paddingBottom: 1,
//         color: 'rgba(255, 255, 255, 0.7)',
//         '&:hover,&:focus': {
//           backgroundColor: 'rgba(255, 255, 255, 0.08)',
//         },
//       },
//       itemCategory: {
//         backgroundColor: '#232f3e',
//         boxShadow: '0 -1px 0 #404854 inset',
//         paddingTop: theme.spacing(2),
//         paddingBottom: theme.spacing(2),
//       },
//       firebase: {
//         fontSize: 24,
//         color: theme.palette.common.white,
//       },
//       itemActiveItem: {
//         color: '#4fc3f7',
//       },
//       itemPrimary: {
//         fontSize: 'inherit',
//       },
//       itemIcon: {
//         color: 'inherit',
//         minWidth: 'auto',
//         marginRight: theme.spacing(2),
//       },
//       collapse: {
//         paddingBottom: theme.spacing(2),
//       },
//       divider: {
//         // marginTop: theme.spacing(2),
//         backgroundColor: '#404854',
//       },
//   });
  
function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Box sx={{ height: '100%', display: 'flex' }}>
                    <CssBaseline />
                    <SideNav />
                    <AppBar />
                    <Box component="main" sx={{ display:'flex', flexDirection: 'column', height: '100%', overflow: 'hidden', 
                        flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
                        <Toolbar /> 
                        <Routes>
                            <Route exact path="/" element={<Activities />}></Route>
                            <Route path="/patients" element={<Patients />}></Route>
                            <Route path="/patients/:id" element={<PatientDetails />}></Route>
                            <Route path="/patients/:patientId/assignments/:assignmentId" element={<PatientResponses />}></Route>
                            <Route path="/patients/new" element={<PatientNew />}></Route>
                            <Route path="/activities" element={<Activities />}></Route>
                            <Route path="/activities/:id" element={<ActivityDetails />}></Route>
                            <Route path="/activities/self" element={<Activities />}></Route>
                            <Route path="/activities/form" element={<ActivityForm />}></Route>
                            <Route path="/activities/form/:id" element={<ActivityForm />}></Route>
                            <Route path="/activities/steps/:id" element={<Steps />}></Route>
                            <Route path="/activities/favorites" element={<Activities />}></Route>
                            <Route path="/login" element={<Login />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export { App, domain };