import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useMutation, gql } from '@apollo/client';
import { Button } from '@mui/material';

const CREATE_PATIENT = gql`
    mutation CreatePatient($clinicianId: Int!, $name: String!) {
        createOcgTPatient(input: {ocgTPatient: {clinicianId: $clinicianId, name: $name}}) {
            ocgTPatient { id }
        }
    }
`;

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#000', 0.15),
    '&:hover': {
        backgroundColor: alpha('#000', 0.25),
    },
    marginRight: '1rem',
    width: 'calc(100% - 16px - 268px)', // 100% - margin width - max pagination width
    // [theme.breakpoints.up('sm')]: {
    //     marginLeft: theme.spacing(3),
    //     width: 'auto',
    // },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit', '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `1rem`,
        transition: theme.transitions.create('width'),
        // commented these out because they were screwing with 100% width of input box
        // width: '100%',
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
        border: '1px solid #0009',
        borderRadius: '5px',
        backgroundColor: 'white'
    }
}));

export default function AddPatient({patientAdded, clinicianId}) {
    const [createPatient, { data, loading, error }] = useMutation(CREATE_PATIENT);
    const [name, setName] = React.useState('');
    function addPatient() {
        createPatient({ variables: { clinicianId, name }})
            .then((result) => {
                setName('');
                patientAdded();
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <div style={{display: 'flex', alignItems: 'center', margin: `1rem 0 2rem 0`}}>
            <Search>
                <StyledInputBase
                    value={name}
                    placeholder="Patient's Name"
                    inputProps={{ 'aria-label': "Patient's Name" }}
                    onChange={e => setName(e.target.value)}
                    style={{width: '100%'}}
                />
            </Search>
            <Button variant="contained" size="small" disableElevation onClick={addPatient} disabled={!name}>Add Patient</Button>
        </div>
    );
}
