import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import FavoriteButton from './FavoriteButton';
import { domain } from '../../App';

export default function ActivityCard({ activity, favoritesId, assign, assignToPatient }) {
    const navigate = useNavigate();
    const tags = activity?.ocgTTagsByActivityId?.nodes;
    function editActivity() {
        navigate(`/activities/${activity.id}`);
    }
    return (
        <Card sx={{ display: 'flex', marginTop: '1rem', height: '170px', cursor: 'pointer' }} onClick={editActivity}>
            <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={`${domain}/files/activities/${activity.id}/${activity.imageFileName}`}
                alt={`${activity.name} image`}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', overflow:'hidden' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {activity.name}
                    </Typography>
                    <Typography variant="description" color="text.secondary" component="div">
                        {activity.description}
                    </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    {tags?.map(tag => (
                        <Chip key={tag.id} label={tag.name} size="small" style={{marginLeft: '0.5rem', borderRadius: '4px'}} />
                    ))}
                </Box>
            </Box>
            <Chip label={activity.activityType} size="small" variant="outlined" style={{margin: '1rem 1rem 0 auto', borderRadius: '4px'}} />
            { !assign ? <FavoriteButton activityId={activity.id} favoritesId={favoritesId} /> 
                : <Button variant="contained" size="small" disableElevation onClick={assignToPatient}
                    style={{height: '30px', margin: '13px 0.5rem 0 0'}}>Assign</Button> }
        </Card>
    );
}