import React from "react";
import { useMutation, gql } from '@apollo/client';
import { useLiveQuery } from 'dexie-react-hooks';
// put this back once they fix broken react 18 bug
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// temp cause of bug
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import db from '../../scripts/db';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isSelected, draggableStyle) => ({
    userSelect: "none",
    padding: '1rem 0.5rem',
    display: 'flex',
    cursor: 'grab',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    // change background colour if dragging
    background: isSelected ? "lightblue" : "#e0e0e0",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "white" : "lightgrey"
});

const UPDATE_STEPS_ORDER = gql`
    mutation UpdateStepsOrder($id: UUID!, $steps: [UUID]!) {
        updateOcgTActivityById(input: {id: $id, ocgTActivityPatch: {steps: $steps}}) {
            ocgTActivity {
                id
            }
        }
    }
`;

export default function StepsList({ activityId, steps, selected, setSteps, setSelected }) {
    const [updateStepsOrder] = useMutation(UPDATE_STEPS_ORDER);
    const stepTypes = useLiveQuery(() => db.stepTypes.toArray(),[]);
    function onClick(index) {
        const step = steps[index];
        setSelected(step.id);
    }
    function onDragStart(e) {
        const step = steps[e.source.index];
        setSelected(step.id);
    }
    function onDragEnd(e) {
        // dropped outside the list
        if (!e.destination) return;

        const newSteps = reorder(
            steps,
            e.source.index,
            e.destination.index
        );
        const newStepOrder = newSteps.map(newStep => newStep.id);
        updateStepsOrder({ variables: { id: activityId, steps: newStepOrder }});
        setSteps(newSteps);
        const step = newSteps[e.destination.index];
        setSelected(step.id);
    }
    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {steps.map((step, index) => (
                        <Draggable key={index + 1} draggableId={(index + 1).toString()} index={index}>
                        {(provided, snapshot) => (
                            <div 
                                ref={provided.innerRef} 
                                {...provided.draggableProps} 
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    // snapshot.isDragging,
                                    step.id === selected,
                                    provided.draggableProps.style
                                )}
                                onClick={() => onClick(index)}
                            >
                                <DragIndicatorIcon style={{marginRight: '1rem', color: '#9e9e9e', height: 'auto'}}/>
                                <div>
                                    {step.title}
                                    <br/>
                                    <small>{stepTypes?.find((stepType) => stepType.id === step.stepType).text}</small>
                                </div>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>
    );
}
