import * as React from 'react';
import { Button, Fab, ToggleButton, ToggleButtonGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { startStreaming, stopStreaming, startRecording, stopRecoring } from './Logic';
import { loadffmpeg } from '../../scripts/ffmpeg';
import Timer from './Timer';

const dialogStyles = {
    backgroundColor: '#ef9a9a',
    margin: '1rem 1rem 1rem calc(240px + 1rem)',
    maxWidth: '1060px',
    width: 'calc(100% - 2rem - 240px)',
    aspectRatio: '1280/720',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '#00000029 2px 2px 5px 0px',
    padding: 0,
    zIndex: 20
};
const dialogContentStyles = {
    width:'100%', 
    height:'100%',
    borderRadius: '5px',
    overflow: 'hidden'
};
const errorDivStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
};
const videoStyles = {
    width: '100%',
    height: '100%'
};
const playButtonDivStyles = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '65px',
    gap: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};
const videoButtonDivStyles = {
    position: 'absolute',
    marginLeft: '1rem',
    top: '0',
    left: '0',
    width: 'calc(100% - 1rem)',
    height: '65px',
    gap: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 5
};

export default function Record({ videoBlob, setVideoBlob, style, disabled }) {
    const dialogRef = React.useRef(null);
    const videoRef = React.useRef(null);
    const [ffmpegLoaded, setffmpegLoaded] = React.useState(false);
    const [recording, setRecording] = React.useState(false);
    const [videoSelection, setVideoSelection] = React.useState('live');
    const [error, setError] = React.useState(false);

    async function loadingffmpeg() {
        setffmpegLoaded('loading');
        const loaded = await loadffmpeg();
        setffmpegLoaded(loaded);
    }
    function handleDataAvailable(event) {
        if (dialogRef.current.open === false) return;
        if (event.data && event.data.size > 0) {
            setVideoBlob(event.data);
        }
    }
    async function startStream() {
        try {
            const stream = await startStreaming(true);
            const videoElement = videoRef.current;
            videoElement.src = null;
            videoElement.srcObject = stream;
        }
        catch(e) {
            setError(true);
        }
    }
    async function startVideoBlob() {
        stop();
        await stopStreaming();
        const videoElement = videoRef.current;
        videoElement.srcObject = null;
        videoElement.src = window.URL.createObjectURL(videoBlob);
        videoElement.play();
    }
    async function open() {
        dialogRef.current.showModal();
        if (videoSelection === 'live') {
            await startStream();
        }
        else {
            await startVideoBlob();
        }
    }
    async function close() {
        dialogRef.current.close();
        stop();
        await stopStreaming();
    }
    function start() {
        setRecording(true);
        setVideoBlob(null);
        startRecording(handleDataAvailable);
    }
    function stop() {
        setRecording(false);
        stopRecoring();
    }
    function recordButton() {
        // if (ffmpegLoaded !== true && !MediaRecorder.isTypeSupported('video/mp4')) {
        // having issues with above code because chrome does semi support video/mp4 but uploads to server fail
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (ffmpegLoaded !== true && !isSafari) {
            return <LoadingButton onClick={loadingffmpeg} variant="outlined" style={style} disableElevation disabled={disabled}
                startIcon={<DownloadRoundedIcon/>} loadingPosition="start" loading={ffmpegLoaded === 'loading'}> Click here to Record now <br/> (Requires 31 MB of space)
            </LoadingButton>
        }
        else if (videoBlob) {
            return <Button onClick={open} variant="contained" style={style} color="success" disableElevation disabled={disabled}>
                <DoneRoundedIcon style={{marginRight:'0.5rem'}} />Recorded
            </Button>
        }
        else {
            return <Button onClick={open} variant="contained" style={style} disableElevation disabled={disabled}>
                <VideoCameraFrontIcon style={{marginRight:'0.5rem'}} />Record
            </Button>
        }
    }
    function startStopFab() {
        if (recording === true) {
            return <Fab size="medium" color="" aria-label="stop recording" onClick={stop}><StopRoundedIcon style={{color:'#d32f2f', transform: 'scale(1.8)'}} /></Fab>
        }
        else {
            return <Fab size="medium" color="" aria-label="start recording" onClick={start}><FiberManualRecordIcon style={{color:'#0000008a', transform: 'scale(1.5)'}} /></Fab>
        }
    }
    return (
        <div>
            {recordButton()}
            <dialog ref={dialogRef} style={dialogStyles} onClick={close}>
                { error === true ?
                    <div onClick={(e)=>e.stopPropagation()} style={errorDivStyles}>
                        <p style={{margin: '2rem', maxWidth: '600px'}}>Error attempting to access camera and mic. 
                        Please reset camera and mic permissions for this website via your browser UI. Then refresh this page and window.</p>
                    </div>
                    :
                    <div onClick={(e)=>e.stopPropagation()} style={dialogContentStyles}>
                        { videoSelection === 'live' ?
                            <video ref={videoRef} playsInline autoPlay muted style={videoStyles} />
                            :
                            <video ref={videoRef} playsInline style={videoStyles} controls />
                        }
                        <div style={videoButtonDivStyles}>
                            <ToggleButtonGroup color="error" value={videoSelection} size="small" variant="contained" exclusive
                                onChange={(e) => setVideoSelection(e.target.value ?? videoSelection)} aria-label="video selection"
                                style={{backgroundColor:'#eeeeee'}}  
                            >
                                <ToggleButton value={videoSelection} onClick={close}><CloseRoundedIcon /></ToggleButton>
                                <ToggleButton value="live" onClick={startStream}>Live</ToggleButton>
                                <ToggleButton value="recording" disabled={!videoBlob} onClick={startVideoBlob}>Play Recording</ToggleButton>
                            </ToggleButtonGroup>

                            <Timer isRunning={recording} />
                        </div>
                        { videoSelection === 'live' ?
                        <div style={playButtonDivStyles}>
                            {startStopFab()}
                        </div> : null }
                    </div>
                }
            </dialog>
        </div>
    );
}