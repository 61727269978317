import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, 
    FormLabel, MenuItem, Radio, RadioGroup, Slide, TextField } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import AddIcon from '@mui/icons-material/Add';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { domain } from '../../App';
import db from '../../scripts/db';
import { transcode } from '../../scripts/ffmpeg';
import { sortStepTypes } from '../../scripts/helpers';
import DeleteStepButton from './DeleteStepButton';
import Timer from './Timer';
import Camera from '../Media/Camera';
import Record from '../Media/Record';

const textFieldStyles = {
    width: 'calc(100% - 1rem)',
    maxWidth: '600px',
    margin: '1rem 0.5rem 0 0.5rem'
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditStepDialog({ openDialog, setOpenDialog, activityId, step, setSteps, setSelected, deleteStep }) {
    const navigate = useNavigate();
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState(false);
    const stepTypes = useLiveQuery(() => db.stepTypes.toArray(),[]);
    const sortedStepTypes = sortStepTypes(stepTypes);
    const [multipleAnswers, setMultipleAnswers] = React.useState(false);
    const [answers, setAnswers] = React.useState([]);
    const [stepType, setStepType] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [instructions, setInstructions] = React.useState('');
    const [timer, setTimer] = React.useState(0);
    const [timerUnit, setTimerUnit] = React.useState('seconds');
    const [timerDisabled, setTimerDisabled] = React.useState('true');
    const [sudsScale, setSudsScale] = React.useState('1-10');
    const [audioType, setAudioType] = React.useState('Upload');
    const [audioUrl, setAudioUrl] = React.useState('');
    const [audioFile, setAudioFile] = React.useState('');
    const [audioBlob, setAudioBlob] = React.useState(null);
    const [videoType, setVideoType] = React.useState('Upload');
    const [videoUrl, setVideoUrl] = React.useState('');
    const [videoFile, setVideoFile] = React.useState('');
    const [videoBlob, setVideoBlob] = React.useState(null);
    const [imageType, setImageType] = React.useState('Take Picture');
    const [imageFile, setImageFile] = React.useState('');
    const [imageBlob, setImageBlob] = React.useState(null);
    const [autoAdvance, setAutoAdvance] = React.useState(false);
    const [journal, setJournal] = React.useState(false);
    function changeMulitpleAnswers(val) {
        const isTrue = (val === 'true');
        setMultipleAnswers(isTrue);
    }
    function changeAnswers(answer, index) {
        setAnswers(answers => [...answers.slice(0, index), answer, ...answers.slice(index + 1)]);
    }
    function addEmptyAnswer() {
        setAnswers(answers => [...answers, '']);
    }
    function removeAnswer(index) {
        setAnswers(answers => answers.filter((_, i) => i !== index));
    }
    function close(event, reason) {
        if (!reason || saving) return;
        setOpenDialog(false);
    }
    function chooseAudioData(audioBlob) {
        if (audioType === 'YouTube' && audioUrl) return audioUrl;
        else if (audioType === 'Upload' && audioFile) return audioFile;
        else if (audioType === 'Record' && audioBlob) return audioBlob;
        else return null;
    }
    function chooseVideoData(transcodedVideoBlob) {
        if (videoType === 'YouTube' && videoUrl) return videoUrl;
        else if (videoType === 'Upload' && videoFile) return videoFile;
        else if (videoType === 'Record' && transcodedVideoBlob) return transcodedVideoBlob;
        else return null;
    }
    function chooseImageData() {
        if (imageType === 'Upload' && imageFile) return imageFile;
        else if (imageType === 'Take Picture' && imageBlob) return imageBlob;
        else return null;
    }
    async function save() {
        try {
            setSaving(true);
            const transcodedVideoBlob = videoBlob && !videoBlob.type.startsWith('video/mp4') ? await transcode(videoBlob) : videoBlob;
            
            let newStep = {
                id: step?.id, stepType, title, instructions, timer, timerUnit, timerDisabled, sudsScale, 
                audioType, audioUrl, audioFile, audioBlob, videoType, videoUrl, videoFile, videoBlob: transcodedVideoBlob, 
                imageFile, autoAdvance, journal, multipleAnswers, answers
            };

            // custom save
            const formData = new FormData();
            if (step) formData.append('id', newStep.id);
            else formData.append('activity_id', activityId);
            formData.append('step_type', newStep.stepType);
            formData.append('title', newStep.title || null);
            formData.append('instructions', newStep.instructions || null);
            formData.append('timer', newStep.timer);
            formData.append('timer_unit', newStep.timerUnit);
            formData.append('timer_disabled', newStep.timerDisabled);
            formData.append('suds_scale', newStep.sudsScale);
            formData.append('audio_type', newStep.audioType);
            formData.append('audio_url', newStep.audioUrl); 
            formData.append('audio', chooseAudioData(audioBlob));
            formData.append('video_type', newStep.videoType);
            formData.append('video_url', newStep.videoUrl); 
            formData.append('video', chooseVideoData(transcodedVideoBlob));
            formData.append('image', chooseImageData());
            formData.append('auto_advance', newStep.autoAdvance);
            formData.append('journal', newStep.journal);
            formData.append('multiple_answers', newStep.multipleAnswers);
            formData.append('answers', newStep.answers.length === 0 ? null : JSON.stringify(newStep.answers));

            const response = await fetch(`${domain}/api/steps/${step?'update':'create'}`, {
                method: 'POST',
                credentials: 'include',
                body: formData
            });
            setSaving(false);
            if (response.status === 200) {
                response.json().then(data => {
                    setSteps(newStep, data, step ? false : true);
                    setOpenDialog(false);
                    setSelected(data?.id);
                }).catch(err => {
                    setError(true);
                    setTimeout(() => setError(false), 2000);
                });
            }
            else if (response.status === 401 || response.status === 403) {
                db.user.update(1, { loggedIn: false });
                navigate('/login', { state: { returnToPage: true }});
            }
            else {
                setError(true);
                setTimeout(() => setError(false), 2000);
            }
        } catch(e) {
            setError(true);
            setSaving(false);
            setTimeout(() => setError(false), 2000);
        }
    }
    React.useEffect(() => {
        if (openDialog === true) {
            console.log('dialog opened')
            console.log(step)
            function setStepProperties(step) {
                setStepType(step.stepType);
                setTitle(step.title);
                setInstructions(step.instructions);
                setTimer(step.timer);
                setTimerUnit(step.timerUnit);
                setTimerDisabled(step.timerDisabled);
                setSudsScale(step.sudsScale);
                setVideoType(step.audioType);
                setVideoUrl(step.audioUrl);
                setVideoFile(step.audioFile);
                setVideoBlob(step.audioBlob);
                setVideoType(step.videoType);
                setVideoUrl(step.videoUrl);
                setVideoFile(step.videoFile);
                setVideoBlob(step.videoBlob);
                setImageFile(step.imageFile);
                setImageBlob(step.imageBlob);
                setAutoAdvance(step.autoAdvance);
                setJournal(step.journal);
                setMultipleAnswers(step.multipleAnswers);
                setAnswers(step.answers);
            }
            if (step) {
                setStepProperties(step);
            }
            else {
                setStepProperties({
                    stepType: '',
                    title: '',
                    instructions: '',
                    timer: 0,
                    timerUnit: 'seconds',
                    timerDisabled: 'true',
                    sudsScale: '1-10',
                    audioType: 'Upload',
                    audioUrl: '',
                    audioFile: '',
                    audioBlob: null,
                    videoType: 'Upload',
                    videoUrl: '',
                    videoFile: '',
                    videoBlob: null,
                    imageType: 'Take Picture',
                    imageFile: '',
                    imageBlob: null,
                    autoAdvance: false,
                    journal: false,
                    multipleAnswers: false,
                    answers: []
                });
            }
        }
    }, [openDialog, step]);
    function displayVideoType() {
        if (videoType === 'YouTube') {
            return (<TextField style={textFieldStyles} label="YouTube URL" color="primary" value={videoUrl || ''} 
                onChange={(e) => setVideoUrl(e.target.value)} disabled={saving} />);
        }
        else if (videoType === 'Upload') {
            return (<TextField style={textFieldStyles} label="Video File" color="primary" type="file"
                        InputLabelProps={{ shrink: true }} inputProps={{accept: "video/mp4" }} 
                        onChange={(e) => setVideoFile(e.target.files[0])} disabled={saving} />);
        }
        else {
            return (<Record style={{margin:'1rem 1rem 0' }} videoBlob={videoBlob} setVideoBlob={setVideoBlob} disabled={saving} />);
        }
    }
    function displayAudioType() {
        if (audioType === 'URL') {
            return (<TextField style={textFieldStyles} label="Website URL" color="primary" value={audioUrl || ''} 
                onChange={(e) => setAudioUrl(e.target.value)} disabled={saving} />);
        }
        else if (audioType === 'Upload') {
            return (<TextField style={textFieldStyles} label="Audio File" color="primary" type="file"
                        InputLabelProps={{ shrink: true }} inputProps={{accept: "audio/*" }} 
                        onChange={(e) => setAudioFile(e.target.files[0])} disabled={saving} />);
        }
        // else {
        //     return (<Record style={{margin:'1rem 1rem 0' }} audioBlob={audioBlob} setVideoBlob={setVideoBlob} disabled={saving} />);
        // }
    }
    function displayImageType() {
        if (imageType === 'Upload') {
            return (<TextField style={textFieldStyles} label="Image File" color="primary" type="file"
                        InputLabelProps={{ shrink: true }} inputProps={{accept: "image/*" }} 
                        onChange={(e) => setImageFile(e.target.files[0])} disabled={saving} />
        )}
        else {
            return (<Camera style={{margin:'1rem 1rem 0'}} imageBlob={imageBlob} setImageBlob={setImageBlob} disabled={saving} />);
        }
    }
    function displayCustomFields(stepType) {
        if (stepType === 0) {
            return (
                <Timer timer={timer} setTimer={setTimer} timerUnit={timerUnit} setTimerUnit={setTimerUnit}
                    disabled={timerDisabled} saving={saving} setDisabled={setTimerDisabled}/>
            );
        }
        else if (stepType === 1) {
            return (
                <React.Fragment>
                    <FormControlLabel style={textFieldStyles} label="Automatically advance user to the next step after video" 
                        control={<Checkbox onChange={() => setAutoAdvance(prevAutoAdvance => !prevAutoAdvance)} checked={autoAdvance} />} />
                    <div style={{margin:'1rem 1rem 0', width: 'calc(100% - 2rem)', maxWidth: '600px'}}>
                        <FormLabel>Video Type</FormLabel>
                        <RadioGroup row aria-labelledby="video type" name="video-type" value={videoType} 
                            onChange={(e) => setVideoType(e.target.value)}>
                            <FormControlLabel value="YouTube" control={<Radio />} label="YouTube" disabled={saving} />
                            <FormControlLabel value="Upload" control={<Radio />} label="Upload" disabled={saving} />
                            <FormControlLabel value="Record" control={<Radio />} label="Record" disabled={saving} />
                        </RadioGroup>
                    </div>
                    {displayVideoType()}
                </React.Fragment>
            );
        }
        else if (stepType === 2) {
            return (
                <React.Fragment>
                    <div style={{margin:'1rem 1rem 0', width: 'calc(100% - 2rem)', maxWidth: '600px'}}>
                        <FormLabel>Image Type</FormLabel>
                        <RadioGroup row aria-labelledby="image type" name="image-type" value={imageType} onChange={(e) => setImageType(e.target.value)}>
                            <FormControlLabel value="Upload" control={<Radio />} label="Upload" disabled={saving} />
                            <FormControlLabel value="Take Picture" control={<Radio />} label="Take Picture" disabled={saving} />
                        </RadioGroup>
                    </div>
                    {displayImageType()}
                    <Timer timer={timer} setTimer={setTimer} timerUnit={timerUnit} setTimerUnit={setTimerUnit}
                        disabled={timerDisabled} saving={saving} setDisabled={setTimerDisabled}/>
                </React.Fragment>
            );
        }
        else if (stepType === 3) {
            return (
                <FormControlLabel style={textFieldStyles} label="Journal Entry" 
                    control={<Checkbox onChange={() => setJournal(prevJournal => !prevJournal)} checked={journal} />} />
            );
        }
        else if (stepType === 6 || stepType === 8) {
            return (
                <div style={{margin:'1rem 1rem 0', width: 'calc(100% - 2rem)', maxWidth: '600px'}}>
                    <FormLabel>SUDs scale</FormLabel>
                    <RadioGroup row aria-labelledby="SUDS scale" value={sudsScale} onChange={(e, newSudsScale) => setSudsScale(newSudsScale)}>
                        <FormControlLabel value="1-10" control={<Radio />} label="0-10" disabled={saving} />
                        <FormControlLabel value="10-100" control={<Radio />} label="0-100" disabled={saving} />
                    </RadioGroup>
                </div>
            );
        }
        else if (stepType === 7) {
            return (
                <React.Fragment>
                    <RadioGroup style={textFieldStyles} row aria-labelledby="question type" value={multipleAnswers} 
                        onChange={(e, newMultipleAnswers) => changeMulitpleAnswers(newMultipleAnswers)}>
                        <FormControlLabel value={false} control={<Radio />} label="Allow only one answer" disabled={saving} />
                        <FormControlLabel value={true} control={<Radio />} label="Allow multiple answers" disabled={saving} />
                    </RadioGroup>
                    <div style={{margin:'1rem 0.5rem 0 1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <FormLabel>Possible Answers</FormLabel>
                        <Button size="small" variant="contained" disableElevation style={{minWidth: '30px', padding: '2px 6px'}} 
                            onClick={addEmptyAnswer} disabled={saving}>
                            {/* <AddIcon /> */}
                            Add answer option
                        </Button>
                    </div>
                    <div style={{flex: 1, overflowY: 'scroll'}}>
                        {answers?.map((answer, index) => (
                            <div key={index} style={{position: 'relative'}}>
                                <TextField style={textFieldStyles} label={`Answer Option ${index + 1}`} color="primary" multiline 
                                    value={answer || ''} onInput={(e) => changeAnswers(e.target.value, index)} disabled={saving} />
                                <Button size="small" variant="contained" color="error" disableElevation onClick={()=> removeAnswer(index)}
                                    style={{minWidth: '30px', padding: '2px', position: 'absolute', right: '16px', top: '30px'}} disabled={saving}>
                                    <RemoveRoundedIcon />
                                </Button>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            );
        }
        else if (stepType === 9) {
            return (
                <React.Fragment>
                    <div style={{margin:'1rem 1rem 0', width: 'calc(100% - 2rem)', maxWidth: '600px'}}>
                        <FormLabel>Audio Type</FormLabel>
                        <RadioGroup row aria-labelledby="audio type" name="audio-type" value={audioType} 
                            onChange={(e) => setAudioType(e.target.value)}>
                            <FormControlLabel value="URL" control={<Radio />} label="Website URL" disabled={saving} />
                            <FormControlLabel value="Upload" control={<Radio />} label="Upload" disabled={saving} />
                            {/* <FormControlLabel value="Record" control={<Radio />} label="Record" disabled={saving} /> */}
                        </RadioGroup>
                    </div>
                    {displayAudioType()}
                </React.Fragment>
            );
        }
    }
    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={close}
            aria-describedby="Edit Step"
            PaperProps={{ sx: { width: "450px", height: "735px" } }}
        >
            <DialogTitle>{ step ? 'Edit' : 'Add' } Step</DialogTitle>
            <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                <TextField style={textFieldStyles} select label='Step Type' color="primary" required disabled={saving}
                    value={sortedStepTypes ? stepType : 0} onChange={(e) => setStepType(e.target.value)} error={stepType === ''}>
                        {sortedStepTypes?.map((stepType) => (
                            <MenuItem key={stepType.id} value={stepType.id}>{stepType.text}</MenuItem>
                        )) || []}
                </TextField>
                {stepType !== '' ?
                    <React.Fragment>
                        { stepType === 7 ?
                            <TextField style={textFieldStyles} label="Question" color="primary"
                                value={title || ''} onChange={(e) => setTitle(e.target.value)} disabled={saving} />
                        :
                            <React.Fragment>
                                <TextField style={textFieldStyles} label="Title displayed at top" color="primary"
                                    value={title || ''} onChange={(e) => setTitle(e.target.value)} disabled={saving} />
                                <TextField style={textFieldStyles} label="Text to be displayed" color="primary" multiline 
                                    value={instructions || ''} onChange={(e) => setInstructions(e.target.value)} disabled={saving} />
                            </React.Fragment>
                        }
                        {displayCustomFields(stepType)} 
                    </React.Fragment>
                : null }
            </DialogContent>
            <DialogActions>
                { saving && stepType === 1 && videoType !== 'YouTube' ? <b style={{marginRight:'auto'}}>Video converting please wait</b> :
                    <React.Fragment>
                        { step ? 
                            <DeleteStepButton id={step?.id} deleteStep={()=>{setOpenDialog(false);deleteStep();}} style={{marginRight:'auto'}} disabled={saving} /> 
                        : null }
                        <Button variant="outlined" size="small" disableElevation onClick={()=>setOpenDialog(false)} disabled={saving}>Cancel</Button>
                    </React.Fragment>
                }
                <LoadingButton variant="contained" size="small" color={error ? 'error' : 'primary'} disableElevation 
                    onClick={save} loading={saving} loadingPosition="start" disabled={stepType === ''}
                    startIcon={error ? <ErrorOutlineRoundedIcon /> : <SaveRoundedIcon />}>{error ? 'Error' : 'Save'}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}