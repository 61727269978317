import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import MuiLink from "@mui/material/Link";

const Link = ({ href, ...otherProps }) => {
  return (
    <MuiLink
      {...otherProps}
      component={ReactRouterLink}
      to={href ?? "#"}
    />
  );
};

export default Link;