import * as React from 'react';
import { Button, Fab, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { startStreaming, stopStreaming } from './Logic';

const dialogStyles = {
    backgroundColor: '#ef9a9a',
    margin: '1rem 1rem 1rem calc(240px + 1rem)',
    maxWidth: '1060px',
    width: 'calc(100% - 2rem - 240px)',
    aspectRatio: '1280/720',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '#00000029 2px 2px 5px 0px',
    padding: 0,
    zIndex: 20
};
const dialogContentStyles = {
    width:'100%', 
    height:'100%',
    borderRadius: '5px',
    overflow: 'hidden'
};
const errorDivStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
};
const cameraStyles = {
    width: '100%',
    height: '100%'
};
const takePictureButtonDivStyles = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '65px',
    gap: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};
const cameraSelectionDivStyles = {
    position: 'absolute',
    marginLeft: '1rem',
    top: '0',
    left: '0',
    width: 'calc(100% - 1rem)',
    height: '65px',
    gap: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 5
};

export default function Camera({ imageBlob, setImageBlob, style, disabled }) {
    const dialogRef = React.useRef(null);
    const videoRef = React.useRef(null);
    const imageRef = React.useRef(null);
    const [cameraSelection, setCameraSelection] = React.useState(imageBlob ? 'picture' : 'live');
    const [error, setError] = React.useState(false);

    async function startStream() {
        try {
            const stream = await startStreaming();
            const videoElement = videoRef.current;
            videoElement.src = null;
            videoElement.srcObject = stream;
        }
        catch(e) {
            setError(true);
        }
    }
    async function open() {
        dialogRef.current.showModal();
        if (imageBlob) {
            await viewPicture();
            imageRef.current.src = URL.createObjectURL(imageBlob);
        }
        else {
            await startStream();
        }
    }
    async function close() {
        dialogRef.current.close();
        await stopStreaming();
    }
    function takePicture() {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(function(blob) {
            setImageBlob(blob);
            canvas.remove();
            viewPicture();
        }, 'image/jpeg');
    }
    function viewStream() {
        setCameraSelection('live');
        startStream();
    }
    async function viewPicture() {
        setCameraSelection('picture');
        await stopStreaming();
    }
    function cameraButton() {
        if (imageBlob) {
            return <Button onClick={open} variant="contained" style={style} color="success" disableElevation disabled={disabled}>
                <DoneRoundedIcon style={{marginRight:'0.5rem'}} />Picture Taken
            </Button>
        }
        else {
            return <Button onClick={open} variant="contained" style={style} disableElevation disabled={disabled}>
                <CameraAltRoundedIcon style={{marginRight:'0.5rem'}} />Take Picture
            </Button>
        }
    }
    React.useEffect(() => {
        if (imageBlob) {
            imageRef.current.src = URL.createObjectURL(imageBlob);
        }
    }, [imageBlob]);
    return (
        <div>
            {cameraButton()}
            <dialog ref={dialogRef} style={dialogStyles} onClick={close}>
                { error === true ?
                    <div onClick={(e)=>e.stopPropagation()} style={errorDivStyles}>
                        <p style={{margin: '2rem', maxWidth: '600px'}}>Error attempting to access camera. 
                        Please reset camera permissions for this website via your browser UI. Then refresh this page and window.</p>
                    </div>
                    :
                    <div onClick={(e)=>e.stopPropagation()} style={dialogContentStyles}>
                        { cameraSelection === 'live' ?
                            <video ref={videoRef} playsInline autoPlay muted style={cameraStyles} />
                            :
                            <img ref={imageRef} style={cameraStyles} alt="webcam capture" />
                        }
                        <div style={cameraSelectionDivStyles}>
                            { cameraSelection === "picture" ? <ToggleButtonGroup color="success" value={cameraSelection} size="small" variant="contained" exclusive
                                onChange={(e) => setCameraSelection(e.target.value)} aria-label="camera selection"
                                style={{backgroundColor:'#eeeeee'}}  
                            >
                                <ToggleButton value="live" onClick={viewStream}>Retake Picture</ToggleButton>
                                <ToggleButton value="picture" disabled={!imageBlob} onClick={close}>Use Picture</ToggleButton>
                            </ToggleButtonGroup> : null }
                        </div>
                        { cameraSelection === 'live' ?
                        <div style={takePictureButtonDivStyles}>
                            <Fab size="medium" color="success" aria-label="take picture" onClick={takePicture}><CameraAltRoundedIcon /></Fab>
                        </div> : null }
                    </div>
                }
            </dialog>
        </div>
    );
}