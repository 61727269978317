import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery, gql } from '@apollo/client';
import { Breadcrumbs, Button, Typography } from '@mui/material';
import Link from '../Link';
import db from '../../scripts/db';
import StepsTable from './StepsTable';
import { breadcrumbStyles } from '../../scripts/styles';
import PublishButton from '../Activities/PublishButton';
import PreviewActivity from '../Activities/PreviewActivity';

const buttonStyles = {
    margin: '1rem 1rem 1rem 0'
}
const GET_ACTIVITY = gql`
    query GetActivity($id: UUID!) {
        ocgTActivityById(id: $id) {
            id
            name
            published
            steps
            clinicianId
            ocgTTagsByActivityId { totalCount }
        }
    }
`;
export default function Steps() {
    const navigate = useNavigate();
    const params = useParams();
    const [openPreviewDialog, setOpenPreviewDialog] = React.useState(false);
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const getActivity = useQuery(GET_ACTIVITY, {
        variables: { id: params.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const activity = getActivity?.data?.ocgTActivityById;
    if (activity) db.appbar.put({ key: 1, title: activity.name });
    React.useEffect(() => {
        if (getActivity?.error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [getActivity, navigate]);
    if (!activity) return null;
    return (
        <div style={{height: '100%'}}>
            <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/activities">Activities</Link>
                <Link underline="hover" color="inherit" href={`/activities/${activity?.id}`}>{activity.name}</Link>
                <Typography color="text.primary">Steps</Typography>
            </Breadcrumbs>
            <Button variant="contained" size="small" disableElevation style={buttonStyles} onClick={() => navigate(`/activities/form/${activity.id}`)}>Edit Settings</Button>
            <Button variant="contained" size="small" disableElevation style={buttonStyles} onClick={() => setOpenPreviewDialog(true)}>Preview</Button>
            <PublishButton activity={activity} clinician={clinician} style={buttonStyles} />
            <Button variant="contained" size="small" disableElevation style={buttonStyles} onClick={() => navigate(`/activities/${activity.id}`)}>Save & Exit</Button>
            <StepsTable activity={activity} />

            <PreviewActivity activity={activity} openDialog={openPreviewDialog} setOpenDialog={setOpenPreviewDialog} />
        </div>
    )
}