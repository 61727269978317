import * as React from 'react';
import { domain } from '../../App';

export default function AudioPreview({ step }) {
    if (!step) return null;

    const audioUrl = step.audioType === 'URL'
        ? `${domain}/proxy?url=${encodeURIComponent(step.audioUrl)}`
        : `${domain}/files/steps/${step?.id}/${step?.audioUrl}`;

    return (
        <audio key={step.id} controls style={{ width: '100%' }}>
            <source src={audioUrl} />
        </audio>
    );
};