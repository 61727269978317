import { gql } from '@apollo/client';
import { jsonToGraphQLQuery, EnumType } from 'json-to-graphql-query';
import { generateArrayOfOrSearchArrays, activityTypes } from '../../scripts/helpers';

function allActivities(searchText, searchTextArr, activityType, clinicianId, activitiesPerPage, offset) {
    const query = {
        query: {
            allOcgTActivities: {
                __args: {
                    filter: {
                        and: generateArrayOfOrSearchArrays(searchText, searchTextArr)    
                    },
                    condition: {
                        published: true,
                        // properties added based on conditions
                        ...(activityType !== 0) && { activityType: activityTypes[activityType] }
                    },
                    first: activitiesPerPage, offset
                },
                nodes: {
                    id: true,
                    name: true,
                    description: true,
                    activityType: true,
                    imageFileName: true,
                    published: true,
                    clinicianId: true,
                    ocgTFavoritesByActivityId: {
                        __args: { condition: { ...(clinicianId) && { clinicianId }}},
                        nodes: { id: true }
                    },
                    ocgTTagsByActivityId: {
                        __args: { orderBy: new EnumType('NAME_ASC') },
                        nodes: { id: true, name: true }
                    }
                },
                totalCount: true
            }
        }
    }
    const gqlString = jsonToGraphQLQuery(query, { pretty: true });
    return gql`${gqlString}`;
}

function myActivities(searchText, searchTextArr, activityType, clinicianId, activitiesPerPage, offset) {
    const query = {
        query: {
            allOcgTActivities: {
                __args: {
                    filter: {
                        and: generateArrayOfOrSearchArrays(searchText, searchTextArr)
                    },
                    condition: {
                        // properties added based on conditions
                        ...(activityType !== 0) && { activityType: activityTypes[activityType] },
                        ...(clinicianId) && { clinicianId }
                    },
                    first: activitiesPerPage, offset
                },
                nodes: {
                    id: true,
                    name: true,
                    description: true,
                    activityType: true,
                    imageFileName: true,
                    published: true,
                    clinicianId: true,
                    ocgTFavoritesByActivityId: {
                        __args: { condition: { ...(clinicianId) && { clinicianId }}},
                        nodes: { id: true }
                    },
                    ocgTTagsByActivityId: {
                        __args: { orderBy: new EnumType('NAME_ASC') },
                        nodes: { id: true, name: true }
                    }
                },
                totalCount: true
            }
        }
    }
    const gqlString = jsonToGraphQLQuery(query, { pretty: true });
    return gql`${gqlString}`;
}

function favoriteActivities(searchText, searchTextArr, activityType, clinicianId, activitiesPerPage, offset) {
    const query = {
        query: {
            allOcgTActivities: {
                __args: {
                    filter: {
                        and: generateArrayOfOrSearchArrays(searchText, searchTextArr),
                        // properties added based on conditions
                        ...(clinicianId) && { ocgTFavoritesByActivityId: { some: { clinicianId: { equalTo: clinicianId }}}}
                    },
                    condition: {
                        // properties added based on conditions
                        ...(activityType !== 0) && { activityType: activityTypes[activityType] }
                    },
                    first: activitiesPerPage, offset
                },
                nodes: {
                    id: true,
                    name: true,
                    description: true,
                    activityType: true,
                    imageFileName: true,
                    published: true,
                    clinicianId: true,
                    ocgTFavoritesByActivityId: {
                        __args: { condition: { ...(clinicianId) && { clinicianId }}},
                        nodes: { id: true }
                    },
                    ocgTTagsByActivityId: {
                        __args: { orderBy: new EnumType('NAME_ASC') },
                        nodes: { id: true, name: true }
                    }
                },
                totalCount: true
            }
        }
    }
    const gqlString = jsonToGraphQLQuery(query, { pretty: true });
    return gql`${gqlString}`;
}

export { allActivities, myActivities, favoriteActivities }