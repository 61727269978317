import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery, gql } from '@apollo/client';
import { Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material/';
import Link from '../Link';
import db from '../../scripts/db';

const ALL_PATIENTS = gql`
    query MyQuery($clinicianId: Int!) {
        allOcgTPatients(
            condition: {clinicianId: $clinicianId}
            # filter: {email: {isNull: false}}
            orderBy: LAST_ACTIVITY_DATE_DESC
        ) { nodes { id name email lastActivityDate }}
    }
`;

export default function Patients() {
    const navigate = useNavigate();
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const { loading, error, data } = useQuery(ALL_PATIENTS, {
        variables: { clinicianId: clinician?.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [error, navigate]);
    React.useEffect(() => {
        db.appbar.put({ key: 1, title: 'Patients' });
    }, []);
    return (
        <div style={{height:'calc(100% - 50px)'}}>
            <Button variant="contained" size="small" disableElevation 
                style={{marginBottom:'1rem'}} onClick={()=>navigate('/patients/new')}>Create New Patient</Button>
            <TableContainer component={Paper} style={{height:'calc(100% - 50px)'}}>
                <Table sx={{ minWidth: 650 }} aria-label="patients table" size="small">
                    <TableHead style={{backgroundColor:'#e0e0e0', position: 'sticky', top: 0, zIndex: 1}}>
                        <TableRow>
                            <TableCell align="left"><b>Patient Name</b></TableCell>
                            <TableCell align="left"><b>Status</b></TableCell>
                            <TableCell align="left"><b>Latest Activity Date</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.allOcgTPatients?.nodes.map((patient) => (
                            <TableRow key={patient.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" component="th" scope="row">
                                    <Link underline="hover" color="inherit" href={`/patients/${patient.id}`} sx={{
                                        color: '#2096f3',
                                        fontWeight: 'bold',
                                        textDecoration: 'underline',
                                        transition: 'color 0.3s ease, text-decoration 0.3s ease',
                                        '&:hover': {
                                            color: 'darkblue',
                                            textDecoration: 'none'
                                        }
                                    }}>
                                        {patient.name}
                                    </Link>
                                </TableCell>
                                <TableCell align="left"><Chip label={patient?.email ? 'accepted' : 'invited'} size="small" style={{borderRadius: '4px'}} /></TableCell>
                                <TableCell align="left">
                                    { patient.lastActivityDate ? 
                                        new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(patient.lastActivityDate))
                                    : null }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}