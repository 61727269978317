function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}
function validatePassword(password) {
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
    return String(password).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
}
function parseIntOrZero(text) {
    const parsedInt = parseInt(text, 10);
    return isNaN(parsedInt) ? 0 : parsedInt;
}
function generateOrSearchArray(searchText) {
    return {
        or: [
            { activityId: { equalTo: parseIntOrZero(searchText) }},
            { name: { includesInsensitive: searchText }},
            { description: { includesInsensitive: searchText }},
            { ocgTTagsByActivityId: { some: { name: { includesInsensitive: searchText }}}},
            { searchTerms: { includesInsensitive: searchText }}
        ]
    };
}
function generateArrayOfOrSearchArrays(searchText, searchTextArr) {
    const result = [];
     // live typing search
    if (searchText) {
        result.push(generateOrSearchArray(searchText));
    }
    // searches saved by hitting enter
    searchTextArr.forEach(text => {
        result.push(generateOrSearchArray(text));
    });
    return result;
}

function sortStepTypes(stepTypes) {
    // Define the custom order
    const stepOrder = [0, 3, 9, 1, 4, 2, 5, 6, 7, 8];

    // Create a map for quick lookup of the order index
    const stepOrderMap = stepOrder.reduce((acc, id, index) => {
        acc[id] = index;
        return acc;
    }, {});

    // Sort the stepTypes array based on the custom order
    const sortedStepTypes = stepTypes?.sort((a, b) => stepOrderMap[a.id] - stepOrderMap[b.id]);

    return sortedStepTypes || [];
}

const activityTypes = ['All Types', 'Psychoeducation', 'Exposure', 'Assessment', 'Mindfulness/Relaxation', 'Reinforcers'];

export { validateEmail, validatePassword, generateArrayOfOrSearchArrays, sortStepTypes, activityTypes };