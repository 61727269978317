import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

const circularProgressStyles = {
    width: '24px',
    height: '24px',
    marginRight: '8px'
};
export default function SaveButton(props) {
    function renderSaveButton(saving=false, error=false, variant, size) {
        if (saving === false && error === false) {
            return  <Button style={{margin: 'auto'}} variant={variant} size={size} color="primary" aria-label="add book" disableElevation onClick={props.save}>
                        <SaveRoundedIcon sx={{ marginRight: '0.5rem' }} /><b>Save</b>
                    </Button>
        }
        else if (saving === true && error === false) {
            return  <Button style={{margin: 'auto'}} variant={variant} size={size} color="primary" aria-label="add book" disableElevation disabled>
                        <CircularProgress style={circularProgressStyles} /><b>Save</b>
                    </Button>     
        }
        else if (error === true) {
            return  <Button style={{margin: 'auto'}} variant={variant} size={size} color="primary" aria-label="add book" disableElevation disabled>
                        <ErrorOutlineRoundedIcon sx={{ marginRight: '0.5rem' }} /><b>Error</b>
                    </Button>     
        }
    }
    return (
        <div style={props.style}>
            {renderSaveButton(props.saving, props.error, props.variant, props.size)}
        </div>
    );
}