import React, { useEffect, useRef, useState } from 'react';
import { animationInterval } from '../../scripts/animationInterval';

function formatTime(seconds) {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedMins = String(mins).padStart(2, '0');
    const formattedSecs = String(secs).padStart(2, '0');
    return `${formattedMins}:${formattedSecs}`;
}

function Timer({ isRunning }) {
    const [time, setTime] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const controllerRef = useRef(new AbortController());

    useEffect(() => {
        const controller = controllerRef.current;
        if (isRunning === true && isActive === false) {
            setIsActive(true);
            animationInterval(1000, controller.signal, () => {
                setTime(prevTime => prevTime + 1);
            });
        }
        else if (isRunning === false && isActive === true) {
            controller.abort();
            controllerRef.current = new AbortController();
            setTimeout(()=> {
                setTime(0);
                setIsActive(false);
            }, 500);
        }
    }, [isRunning, time, isActive]);

    const timerStyle = {
        width: '56px',
        height: '24px',
        backgroundColor: '#d32f2f',
        borderRadius: '12px',
        display: 'flex',
        opacity: isRunning ? 1 : 0,
        transition: 'opacity 0.5s',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    };

    return (
        <div style={timerStyle}>
            {formatTime(time)} 
        </div>
    );
}

export default Timer;
