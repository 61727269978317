import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup, Slide, Slider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import YouTubePlayer from '../YouTubePlayer';
import { domain } from '../../App';
import AudioPreview from '../Media/AudioPreview';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ALL_STEPS = gql`
    query AllSteps($activityId: UUID!) {
        allOcgTSteps(condition: {activityId: $activityId}) {
            nodes {
                id
                imageUrl
                instructions
                stepType
                timer
                timerUnit
                timerDisabled
                sudsScale
                title
                audioType
                audioUrl
                videoType
                videoUrl
                autoAdvance
                journal
                multipleAnswers
                answers
            }
        }
    }
`;

export default function PreviewActivity({ activity, openDialog, setOpenDialog }) {
    const navigate = useNavigate();
    const [SUD, setSUD] = React.useState(0);
    const [steps, setSteps] = React.useState([]);
    const [stepIndex, setStepIndex] = React.useState(0);
    const step = steps[stepIndex];
    const allSteps = useQuery(ALL_STEPS, {
        variables: { activityId: activity?.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    function prev() {
        setStepIndex((prevStepIndex) => {
            // Ensure the previous step is within bounds
            const newStepIndex = prevStepIndex - 1;
            return Math.max(0, newStepIndex);
        });
    }
    function next() {
        setStepIndex((prevStepIndex) => {
            // Ensure the next step is within bounds
            const newStepIndex = prevStepIndex + 1;
            return Math.min(newStepIndex, steps.length - 1);
        });
    }
    function renderOtherFields() {
        if (step?.stepType === 1) {
            if (step?.videoType === 'YouTube') {
                return step?.videoUrl ? <YouTubePlayer url={step?.videoUrl} width="100%" height="200px" /> : null;
            }
            else {
                return (
                    <video key={step.id} playsInline style={{width: '100%'}} controls>
                        <source src={`${domain}/files/steps/${step?.id}/${step?.videoUrl}`} />
                    </video>
                );
            }
        }
        else if (step?.stepType === 2) {
            return <img src={`${domain}/files/steps/${step?.id}/${step?.imageUrl}`} style={{width: '100%'}} alt={step?.title} />;
        }
        else if (step?.stepType === 3) {
            return <TextField label="Type in your response here..." multiline rows={12} style={{width:'100%'}} />;
        }
        else if (step?.stepType === 4) {
            return <Button aria-label="Record a Video" variant="contained" disableElevation>Record a Video</Button>;
        }
        else if (step?.stepType === 5) {
            return <Button aria-label="Take a Picture" variant="contained" disableElevation>Take a Picture</Button>;
        }
        else if (step?.stepType === 6) {
            const sudsScaleArr = step?.sudsScale?.split('-');
            return <Slider aria-label="SUDs Rating" value={SUD} onChange={(e, newValue) => setSUD(newValue)} 
                valueLabelDisplay="auto" step={sudsScaleArr[0] ?? 1} max={sudsScaleArr[1] ?? 10} sx={{
                    marginTop: '2rem',
                    width: '90%',
                    "& .MuiSlider-valueLabelLabel": {
                      fontSize: "20px",
                      fontWeight: "bold"
                    }
                  }}/>;
        }
        else if (step?.stepType === 7) {
            return (
                <RadioGroup style={{display: 'flex', alignContent: 'flex-start', marginLeft: '1rem'}}>
                    {step?.answers?.map((answer, index) => (
                         <FormControlLabel key={index} value={answer} label={answer} style={{ textAlign: 'left' }}
                            control={step?.multipleAnswers ? <Checkbox /> : <Radio />} />
                    ))}
                </RadioGroup>
            );
        }
        else if (step?.stepType === 9) {
           return (<AudioPreview step={step} />);
        }
    }
    React.useEffect(() => {
        const allStepsError = allSteps?.error;
        if (allStepsError?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
            return;
        }
        let newSteps = allSteps?.data?.allOcgTSteps?.nodes;
        if (newSteps) {
            const newStepsSorted = [...newSteps].sort(function (a, b) {
                return activity.steps.indexOf(a.id) - activity.steps.indexOf(b.id);
            });
            setSteps(newStepsSorted);
        }
    }, [activity, allSteps, setSteps, navigate]);
    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenDialog(false)}
            aria-describedby="preview activity"
            PaperProps={{ sx: { width: "400px", height: "800px" } }}
            TransitionProps={{ onExited: () => setStepIndex(0) }}
        >
            { openDialog ? 
                <React.Fragment>
                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center'}}>
                        <IconButton aria-label="close" onClick={() => setOpenDialog(false)}><CloseIcon /></IconButton>
                        <DialogTitle>Preview Mode</DialogTitle>
                        <ButtonGroup aria-label="Previous and Next" size="small" variant="contained" disableElevation>
                            <Button onClick={prev} disabled={stepIndex === 0}>Prev</Button>
                            <Button onClick={next} disabled={stepIndex === steps.length -1}>Next</Button>
                        </ButtonGroup>
                    </div>
                    <DialogContent style={{paddingTop:'1.5rem', textAlign:'center'}}>
                        {step?.stepType === 7 ? <h3>{step?.title}</h3> : <h2>{step?.title}</h2> }
                        <p style={{marginBottom: '2rem'}}>{step?.instructions}</p>
                        {renderOtherFields()}
                    </DialogContent>
                    <DialogActions>
                        <small><b>{`Step ${stepIndex + 1} of ${steps.length}`}</b></small>
                    </DialogActions>
                </React.Fragment>
            : null }
        </Dialog>
    );
}