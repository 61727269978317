import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { domain } from '../../App';

export default function DeleteStepButton({ id, deleteStep, style, disabled }) {
    const navigate = useNavigate();
    async function removeStep() {
        await fetch(`${domain}/api/steps/delete`, { 
            method: 'POST', 
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ id })
        }).then(res => {
            if (res.status === 200) deleteStep();
            else if (res.status === 401) {
                navigate('/login', { state: { returnToPage: true }});
            }
        });
    }
    return (
        <Button onClick={removeStep} disabled={disabled} color="error" variant="outlined" size="small" style={style}>Delete</Button>
    );    
}