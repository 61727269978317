import * as React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, 
    DialogTitle, FormControlLabel, Radio, RadioGroup, Slide, TextField } from '@mui/material';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const datePickerStyles = {
    backgroundColor: "aliceblue",
    width: '100%',
    height: "24px",
    borderRadius: "8px",
    fontSize: "14px",
    padding: "3px 10px"
};

const CREATE_ASSIGNMENT = gql`
    mutation CreateAssignment($activityId: UUID!, $clinicianId: Int!, $patientId: Int!, 
                                $dueDate: Datetime!, $endDate: Datetime!, $selfAssignable: Boolean!, $batchId: UUID) {
        createOcgTAssignment(
            input: {ocgTAssignment: {activityId: $activityId, clinicianId: $clinicianId, patientId: $patientId, 
                dueDate: $dueDate, endDate: $endDate, selfAssignable: $selfAssignable, batchId: $batchId}}
        ) {
            ocgTAssignment { id }
        }
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AssignActivityDatesDialog({ activityId, clinicianId, patientId, openDialog, setOpenDialog, assignmentsCreated }) {
    const calendarRef = React.useRef();
    const [createAssignment, { data, loading, error }] = useMutation(CREATE_ASSIGNMENT);
    const [assignType, setAssignType] = React.useState('select');
    const [selfAssignable, setSelfAssignable] = React.useState(false);
    const [dates, setDates] = React.useState(null);
    const [timesPerDay, setTimesPerDay] = React.useState(1);
    const [totalDays, setTotalDays] = React.useState(6);
    const [timesPerWeek, setTimesPerWeek] = React.useState(1);
    const [totalWeeks, setTotalWeeks] = React.useState(1);
    const [daysStartDate, setDaysStartDate] = React.useState(getDatePlusOneDay());
    const [weeksStartDate, setWeeksStartDate] = React.useState(getDatePlusOneDay());

    function getDateObject(dateString) {
        // Split the date string into components
        // Create a Date object in the local time zone
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    }
    function getDatePlusOneDay() {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 1); // add 1 day
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    function scheduleSelected() {
        Promise.allSettled(
            dates.map((date) => {
                const dueDate = new Date(date).toISOString();   
                return createAssignment({
                    variables: { activityId, clinicianId, patientId, dueDate, endDate: dueDate, selfAssignable, batchId: null },
                });
            })
            ).then((results) => {
                setOpenDialog(false);
                setTimeout(() => {
                    assignmentsCreated(patientId);
                }, "400");
            }).catch((error) => {
                console.error(error);
            });
    }
    function scheduleDaily() {
        const promises = [];
        const startDate = getDateObject(daysStartDate);
        for (let i = 0; i < totalDays; i++) {
            const dueDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + i));
            for (let j = 0; j < timesPerDay; j++) {
                promises.push(
                    createAssignment({
                        variables: { 
                            activityId, clinicianId, patientId, 
                            dueDate, endDate: dueDate.toISOString(), selfAssignable, batchId: null 
                        }
                    })
                );
            }
        }
        Promise.allSettled(promises).then((results) => {
            setOpenDialog(false);
            setTimeout(() => {
                assignmentsCreated(patientId);
            }, 400);
        }).catch((error) => {
            console.error(error);
        });
    }
    function scheduleWeekly() {
        const promises = [];
        const startDate = getDateObject(weeksStartDate);
        for (let i = 0; i < totalWeeks; i++) {
            const batchId = window.crypto.randomUUID();
            const firstDueDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + i * 7));
            const endDate = new Date(firstDueDate);
            endDate.setDate(firstDueDate.getDate() + 6);
            for (let j = 0; j < timesPerWeek; j++) {
                const dueDate = new Date(firstDueDate);
                dueDate.setDate(firstDueDate.getDate() + j);
                promises.push(
                    createAssignment({
                        variables: { 
                            activityId, clinicianId, patientId, 
                            dueDate: dueDate.toISOString(), endDate: endDate.toISOString(), selfAssignable, batchId },
                    })
                );
            }
        }
        Promise.allSettled(promises).then((results) => {
            setOpenDialog(false);
            setTimeout(() => {
                assignmentsCreated(patientId);
            }, 400);
        }).catch((error) => {
            console.error(error);
        });
    }
    function schedule() {
        if (assignType === 'select') {
            scheduleSelected();
        }
        else if (assignType === 'daily') {
            scheduleDaily();
        }
        else if (assignType === 'weekly') {
            scheduleWeekly();
        }
    }      
    const handleClose = () => {
        setOpenDialog(false);
    };
    function renderAssignType() {
        if (assignType === 'select') {
            return (
                <React.Fragment>
                    <DialogContentText style={{margin:'1.5rem 0'}}>Which days would you like to assign this activity?</DialogContentText>
                    <DatePicker format="MM/DD/YYYY" ref={calendarRef} value={dates} onChange={setDates} containerStyle={{width: "385px"}} style={datePickerStyles} 
                        open={true} onClose={() => false} plugins={[<DatePanel sort="date" />]} multiple/>
                </React.Fragment>
            );
        }
        else if (assignType === 'daily') {
            return (
                <React.Fragment>
                    <h4>Assign </h4>
                    <TextField label="time(s) per day" color="primary" type="number" size='small' value={timesPerDay} style={{width:'120px'}} inputProps={{min:1, max:4}} onChange={(e) => setTimesPerDay(e.target.value)} />
                    <h4>for </h4>
                    <TextField label="days" color="primary" type="number" size='small' value={totalDays} style={{width:'60px', marginRight:'1rem'}} inputProps={{min:1, max:7}} onChange={(e) => setTotalDays(e.target.value)} />
                    <TextField label="starting" color="primary" type="date" size='small' value={daysStartDate} onChange={(e) => setDaysStartDate(e.target.value)} />
                </React.Fragment>
            );
        }
        else if (assignType === 'weekly') {
            return (
                <React.Fragment>
                    <h4>Assign </h4>
                    <TextField label="time(s) per week" color="primary" type="number" size='small' value={timesPerWeek} style={{width:'120px'}} inputProps={{min:1, max:7}} onChange={(e) => setTimesPerWeek(e.target.value)} />
                    <h4>for </h4>
                    <TextField label="weeks" color="primary" type="number" size='small' value={totalWeeks} style={{width:'60px', marginRight:'1rem'}} inputProps={{min:1, max:4}} onChange={(e) => setTotalWeeks(e.target.value)} />
                    <TextField label="starting" color="primary" type="date" size='small' value={weeksStartDate} onChange={(e) => setWeeksStartDate(e.target.value)} />
                </React.Fragment>
            );
        }

    }
    React.useEffect(() => {
        if (openDialog && calendarRef.current) {
            calendarRef.current.openCalendar();
        }
    }, [openDialog]);
    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="Schedule Your Assignment"
            PaperProps={{ sx: { width: "500px", height: "620px" } }}
        >
            <DialogTitle>Schedule Your Assignment</DialogTitle>
            <DialogContent style={{overflow:'hidden'}}>
                <FormControlLabel style={{marginBottom:'1rem', marginTop:'1rem'}} label="Allow patient to repeat this assignment" 
                    control={<Checkbox onChange={() => setSelfAssignable(prevSelfAssignable => !prevSelfAssignable)} checked={selfAssignable} />} />
                <RadioGroup row aria-labelledby="assign type" name="assign-type" value={assignType}
                    onChange={(e) => setAssignType(e.target.value)}>
                    <FormControlLabel value="select" control={<Radio />} label="Select Days" />
                    <FormControlLabel value="daily" control={<Radio />} label="Assign Daily" />
                    <FormControlLabel value="weekly" control={<Radio />} label="Assign Weekly" />
                </RadioGroup>
                {renderAssignType()}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small" disableElevation onClick={handleClose}>Cancel</Button>
                <Button variant="contained" size="small" disableElevation onClick={schedule}>Schedule</Button>
            </DialogActions>
        </Dialog>
    );
}