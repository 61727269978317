import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button, Breadcrumbs, Chip, Paper, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Typography } from '@mui/material/';
import Link from '../Link';
import db from '../../scripts/db';
import { breadcrumbStyles } from '../../scripts/styles';
import AddPatient from './AddPatient';

const ALL_PATIENTS = gql`
    query MyQuery($clinicianId: Int!) {
        allOcgTPatients(
            condition: {clinicianId: $clinicianId}
            filter: {email: {isNull: true}}
            orderBy: CREATED_AT_DESC
        ) { nodes { id name invitationCode, createdAt }}
    }
`;
const DELETE_PATIENT = gql`
    mutation MyMutation($id: Int!) {
        deleteOcgTPatientById(input: {id: $id}) {
            ocgTPatient { id }
            }
        }
`;

export default function Patients() {
    const navigate = useNavigate();
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const { loading, error, data, refetch } = useQuery(ALL_PATIENTS, {
        variables: { clinicianId: clinician?.id },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const [deletePatient] = useMutation(DELETE_PATIENT);
    function removePatient(id) {
        deletePatient({ variables: { id }})
            .then((result) => {
                refetch();
            })
            .catch((err) => {
                console.error(err);
            });
    }
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [error, navigate]);
    React.useEffect(() => {
        db.appbar.put({ key: 1, title: 'New Patients' });
    }, []);
    return (
        <React.Fragment>
             <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/patients">Patients</Link>
                <Typography color="text.primary">New Patient Instructions</Typography>
            </Breadcrumbs>
            <AddPatient patientAdded={() => refetch()} clinicianId={clinician?.id} />
            <TableContainer style={{marginTop: '2rem'}} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="patients table">
                    <TableHead style={{backgroundColor:'#e0e0e0', position: 'sticky', top: 0, zIndex: 1}}>
                        <TableRow>
                            <TableCell align="center"><b>Activation code: Patient needs this # to register</b></TableCell>
                            <TableCell align="center"><b>Invitee Name</b></TableCell>
                            <TableCell align="center"><b>Date Sent</b></TableCell>
                            <TableCell align="center"><b>Status</b></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.allOcgTPatients?.nodes.map((patient) => (
                            <TableRow key={patient.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="center" component="th" scope="row">{patient.invitationCode}</TableCell>
                                <TableCell align="center" component="th" scope="row">{patient.name}</TableCell>
                                <TableCell align="center">{new Intl.DateTimeFormat('en-US', { dateStyle: 'full'}).format(new Date(patient.createdAt))}</TableCell>
                                <TableCell align="center"><Chip label="invited" size="small" style={{borderRadius: '4px'}} /></TableCell>
                                <TableCell align="center">
                                    <Button size="small" variant="outlined" color="error" onClick={()=>removePatient(patient.id)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}