import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import ActivityTypeFilter from './ActivityTypeFilter';
import AssignmentTypeFilter from './AssignmentTypeFilter';
import SearchBar from '../SearchBar';
import db from '../../scripts/db';
import ActivityCard from './ActivityCard';
import AssignActivityDatesDialog from './AssignActivityDatesDialog';
import { allActivities, myActivities, favoriteActivities } from './gqlQueries';
import { activityTypes } from '../../scripts/helpers';

const activitiesPerPage = 10;

function getQuery(assignmentType, searchText, searchTextArr, activityType, clinician, offset) {
    if (assignmentType === 'My Activities') {
        return myActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    } else if (assignmentType === 'My Favorites') {
        return favoriteActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    } else {
        return allActivities(searchText, searchTextArr, activityType, clinician?.id, activitiesPerPage, offset);
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AssignActivityDialog({ clinicianId, patientId, openDialog, setOpenDialog, assignmentsCreated }) {
    const endOfScrollRef = React.useRef(null);
    const observer = React.useRef(null);
    const navigate = useNavigate();
    const [openDatesDialog, setOpenDatesDialog] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchTextArr, setSearchTextArr] = React.useState([]);
    const [assignmentType, setAssignmentType] = React.useState("Public Activity Library");
    const [activityType, setActivityType] = React.useState(0);
    const [activityId, setActivityId] = React.useState(null);
    const [activities, setActivities] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const query = getQuery(assignmentType, searchText, searchTextArr, activityType, clinician, offset);
    const { loading, error, data } = useQuery(query, {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const gqlActivities = data?.allOcgTActivities;
    const moreActivitiesToLoad = gqlActivities?.totalCount > (offset + activitiesPerPage);

    const intersectionCallback = React.useCallback((entries) => {
        const lastEntry = entries[entries.length - 1];
        if (lastEntry.isIntersecting && moreActivitiesToLoad) {
            setOffset(offset + activitiesPerPage);
        }
    }, [offset, moreActivitiesToLoad ]);

    function assignToPatient(e, id) {
        e.stopPropagation();
        setActivityId(id);
        setOpenDialog(false);
        setTimeout(() => {
            setOpenDatesDialog(true);
        }, "400");
    }
    const changeSearchText = (newValue) => {
        setOffset(0);
        setActivities([]);
        setSearchText(newValue);
    }
    const changeSearchTextArr = (newValue) => {
        setOffset(0);
        setActivities([]);
        setSearchTextArr(newValue);
    }
    const changeAssignmentType = (e, newValue) => {
        setOffset(0);
        setActivities([]);
        setAssignmentType(newValue);
    }
    const changeActivityType = (e, newValue) => {
        setOffset(0);
        setActivities([]);
        setActivityType(newValue);
    }
    const handleClose = () => {
        setOpenDialog(false);
    };

    React.useEffect(() => {
        // enter when adding to search text array
        if (data?.allOcgTActivities?.nodes && searchTextArr.length > 0) {
            setActivities(data?.allOcgTActivities?.nodes);
        }
        // enter all other time and when scrolling
        else if (data?.allOcgTActivities?.nodes) {
            setActivities(prevActivities => [...prevActivities, ...data?.allOcgTActivities?.nodes]);
        }
    }, [searchTextArr, data]);
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [error, navigate]);
    React.useEffect(() => {
        if (openDialog) {
            // Create IntersectionObserver
            observer.current = new IntersectionObserver(intersectionCallback, { root: null, rootMargin: "0px", threshold: 0.5 });
            observer.current.observe(endOfScrollRef.current);
        }
 
        // Cleanup
        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [intersectionCallback, openDialog]);

    function displayActivities(activity) {
        const favoritesId = activity?.ocgTFavoritesByActivityId?.nodes?.[0]?.id;
        return <ActivityCard key={activity.id} activity={activity} favoritesId={favoritesId} assign={true} 
            assignToPatient={(e) => assignToPatient(e, activity.id)} />;
    }

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="assign activity"
            PaperProps={{ sx: { width: "1080px", maxWidth:'1080px', height: "900px", overflow: 'hidden' } }}
        >
            <DialogTitle style={{textAlign: 'center', backgroundColor:'#f9f9f9'}}>Assign an Activity</DialogTitle>
            <div style={{padding:'0 24px', backgroundColor:'#f9f9f9'}}>
                <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column', gap: '1rem'}}>
                    <AssignmentTypeFilter assignmentType={assignmentType} changeAssignmentType={changeAssignmentType} />
                    <ActivityTypeFilter activityType={activityType} changeActivityType={changeActivityType} />
                </div>
                <div style={{display:'flex'}}>
                    <SearchBar searchText={searchText} setSearchText={changeSearchText} setSearchTextArr={changeSearchTextArr} 
                        activityType={activityTypes[activityType]} 
                        clinician_id={assignmentType === 'My Activities' ? clinician?.id : null} 
                        published={assignmentType === 'Public Activity Library' ? true : null} 
                        fav={assignmentType === 'My Favorites' ? true : null} />
                </div>
            </div>
            <DialogContent style={{overflow:'scroll'}}>
                <div style={{ overflow:'scroll' }}>
                    {activities?.map(activity => displayActivities(activity))}
                    <div ref={endOfScrollRef} style={{textAlign: 'center', marginTop: '0.5rem'}}>
                        { loading || moreActivitiesToLoad ? 'Loading...' : '' }
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{backgroundColor:'#f9f9f9'}}>
                <Button variant="contained" size="small" disableElevation onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <AssignActivityDatesDialog activityId={activityId} clinicianId={clinicianId} patientId={patientId}
                openDialog={openDatesDialog} setOpenDialog={setOpenDatesDialog} assignmentsCreated={assignmentsCreated} />
        </Dialog>
    );
}