import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLiveQuery } from 'dexie-react-hooks';
import { useQuery, gql } from '@apollo/client';
import { Button, Breadcrumbs, Chip, Paper, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow, Typography } from '@mui/material/';
import Link from '../Link';
import { breadcrumbStyles } from '../../scripts/styles';
import db from '../../scripts/db';
import { domain } from '../../App';

const dialogStyles = {
    margin: '1rem 1rem 1rem calc(240px + 1rem)',
    maxWidth: '1060px',
    width: 'calc(100% - 2rem - 240px)',
    aspectRatio: '1280/720',
    border: 'none',
    borderRadius: '5px',
    boxShadow: '#00000029 2px 2px 5px 0px',
    padding: 0,
    zIndex: 20
};
const GET_PATIENT = gql`
    query GetPatient($id: Int!) {
        ocgTPatientById(id: $id) {
            name
            email
            id
            clinicianId
            createdAt
        }
    }
`;
const GET_ACTIVITY = gql`
    query GetActivity($id: Int!) {
        ocgTAssignmentById(id: $id) {
            dueDate
            ocgTActivityByActivityId { name steps }
        }
    }
`;
const GET_PATIENT_RESPONSES = gql`
    query GetPatientResponses($assignmentId: Int!) {
        allOcgTPatientResponses(condition: {assignmentId: $assignmentId}, orderBy: CREATED_AT_ASC) {
            nodes {
                id
                data
                imageUrl
                videoUrl
                createdAt
                ocgTStepByStepId {
                    stepType
                    title
                    instructions
                    answers
                }
            }
        }
    }
`;

export default function PatientResponses() {
    const navigate = useNavigate();
    const params = useParams();
    const dialogRef = React.useRef(null);
    const [dialogResponse, setDialogResponse] = React.useState(null);
    const patientId = parseInt(params?.patientId);
    const assignmentId = parseInt(params?.assignmentId);
    const stepTypes = useLiveQuery(() => db.stepTypes.toArray(),[]);
    const getPatient = useQuery(GET_PATIENT, {
        variables: { id: patientId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getActivity = useQuery(GET_ACTIVITY, {
        variables: { id: assignmentId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getPatientResponses = useQuery(GET_PATIENT_RESPONSES, {
        variables: { assignmentId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const getPatientError = getPatient?.error;
    const getActivityError = getActivity?.error;
    const getPatientResponsesError = getPatientResponses?.error;
    const patient = getPatient?.data?.ocgTPatientById;
    const assignment = getActivity?.data?.ocgTAssignmentById;
    const activity = assignment?.ocgTActivityByActivityId;
    const responses = getPatientResponses?.data?.allOcgTPatientResponses;
    React.useEffect(() => {
        if (getPatientError?.networkError?.statusCode === 401
            || getActivityError?.networkError?.statusCode === 401
            || getPatientResponsesError?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [getPatientError, getActivityError, getPatientResponsesError, navigate]);
    React.useEffect(() => {
        db.appbar.put({ key: 1, title: 'Assignment Data' });
    }, []);
    function showDialog(response) {
        setDialogResponse(response);
        dialogRef.current.showModal();
    }
    async function close() {
        dialogRef.current.close();
        setDialogResponse(null);
    }
    function safeParsePatientJson(data) {
        try {
            const parsed = JSON.parse(data);
            return parsed?.data || '';
        } catch (error) {
            console.info('Failed to parse JSON:', error);
            console.info('Data:', data);
            return '';
        }
    }    
    function renderTimelineDate(assignment) {
        if (assignment?.dueDate) {
            return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(assignment?.dueDate));
        }
        else return '';
    }
    function renderData(response) {
        if (response?.ocgTStepByStepId?.stepType === 4) {
            return <TableCell align="center" style={{backgroundColor:'#efefef'}}>
                    <Button variant="outlined" size="small" onClick={() => showDialog(response)} disableElevation>Play Video</Button>
                </TableCell>;
        }
        if (response?.ocgTStepByStepId?.stepType === 5) {
            return <TableCell align="center" style={{backgroundColor:'#efefef'}}>
                    <Button variant="outlined" size="small" onClick={() => showDialog(response)} disableElevation>Show Image</Button>
                </TableCell>;
        }
        else return <TableCell align="center" style={{backgroundColor:'#efefef'}}>{safeParsePatientJson(response?.data)}</TableCell>;
    }
    return (
        <React.Fragment>
            <Breadcrumbs sx={breadcrumbStyles} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/patients">Patients</Link>
                <Link underline="hover" color="inherit" href={`/patients/${patientId}`}>{patient?.name}</Link>
                <Typography color="text.primary">Assignment Data</Typography>
            </Breadcrumbs>
            <Typography style={{margin:'2rem 0', fontSize:'2rem', height: '40px'}} variant="h4" noWrap component="div">
                Detailed View:<b>{` ${activity?.name || ''}`}</b> on <b>{renderTimelineDate(assignment)}</b>
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="patient responses table" size="small">
                    <TableHead style={{backgroundColor:'#e0e0e0'}}>
                        <TableRow>
                            <TableCell align="left"><b>Step Type</b></TableCell>
                            <TableCell align="left"><b>Completed At</b></TableCell>
                            <TableCell align="left"><b>Step Title</b></TableCell>
                            <TableCell align="left"><b>Step Description</b></TableCell>
                            <TableCell align="center" style={{width: '43%'}}><b>Patient Data</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {responses?.nodes.map((response) => (
                            <TableRow key={response.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" component="th" scope="row">
                                    <Chip size="small" label={stepTypes?.find((stepType) => stepType.id === response?.ocgTStepByStepId?.stepType).text} style={{borderRadius: '4px'}} />
                                </TableCell>
                                <TableCell align="left" component="th" scope="row">
                                    {new Intl.DateTimeFormat('en-US', { timeStyle: 'medium' }).format(new Date(response?.createdAt))}
                                </TableCell>
                                <TableCell align="left">{response?.ocgTStepByStepId?.title}</TableCell>
                                { response?.ocgTStepByStepId?.stepType === 7 ?
                                    <TableCell align="left">
                                        {response.ocgTStepByStepId.answers.map((answer, index) => (
                                            <span key={index}>
                                                <span>{index + 1}. </span>
                                                <span dangerouslySetInnerHTML={{ __html: answer }} />
                                                <br />
                                            </span>
                                        ))}
                                    </TableCell>
                                    :
                                    <TableCell align="left">{response?.ocgTStepByStepId?.instructions}</TableCell>
                                }
                                {renderData(response)}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <dialog ref={dialogRef} style={dialogStyles} onClick={close}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', height: '100%'}}>
                    { dialogResponse?.ocgTStepByStepId?.stepType === 4 ? 
                    <video key={dialogResponse?.id} playsInline style={{width: '100%'}} controls>
                        <source src={`${domain}/files/patient-responses/${dialogResponse?.id}/${dialogResponse?.videoUrl}`} />
                    </video> : null }

                    { dialogResponse?.ocgTStepByStepId?.stepType === 5 ? 
                    <img src={`${domain}/files/patient-responses/${dialogResponse?.id}/${dialogResponse?.imageUrl}`} style={{height: '100%'}} alt={dialogResponse?.ocgTStepByStepId?.title} />
                    : null }
                </div>
            </dialog>
        </React.Fragment>
    )
}