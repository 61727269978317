import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent,
    DialogTitle, MenuItem, Slide, TextField } from '@mui/material';
import { domain } from '../../App';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function CopyPatientDialog({ activity, openDialog, setOpenDialog }) {
    const navigate = useNavigate();
    const copy = async () => {
        await fetch(`${domain}/api/activities/copy`, {
            method: 'POST', 
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ id: activity.id })
        }).then(async res => {
            if (res.status === 200) {
                setOpenDialog(false);
                const payload = await res.json();
                navigate(`/activities/${payload.id}`);
            }
            else if (res.status === 401) {
                navigate('/login', { state: { returnToPage: true }});
            }
        });
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="select patient"
            PaperProps={{ sx: { width: "300px", height: "260px" } }}
        >
            <DialogTitle style={{padding:'16px 24px 0 24px'}}>Copy Activity</DialogTitle>
            <DialogContent style={{paddingTop:'1.5rem'}}>
                This will copy <b>{activity.name}</b>, all associated <b>steps</b>, and all associated <b>search terms</b>. You will be the owner of the new activity.
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small" disableElevation onClick={handleClose}>Cancel</Button>
                <Button variant="contained" size="small" disableElevation onClick={(e) => copy(e)}>Confirm Copy</Button>
            </DialogActions>
        </Dialog>
    );
}