import * as React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useNavigate } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import db from '../scripts/db';

export default function SideNav() {
  const appbar = useLiveQuery(() => db.appbar.limit(1).toArray())?.[0];
  const navigate = useNavigate();
  return (
      <AppBar position="fixed" sx={{ width: `calc(100% - 240px)`, ml: `240px` }}>
          <Toolbar style={{display:'flex'}}>
              <Typography style={{flexGrow:'2'}} variant="h4" noWrap component="div">{appbar?.title}</Typography>
              <Button style={{marginLeft: '1rem'}} variant="contained" color="success" 
                  onClick={()=>navigate('/activities/form')} disableElevation>Create Activity</Button>
          </Toolbar>
      </AppBar>
  );
}