import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent,
    DialogTitle, MenuItem, Slide, TextField } from '@mui/material';
import AssignActivityDatesDialog from '../Activities/AssignActivityDatesDialog';

const GET_PATIENTS = gql`
    query GetPatients($clinicianId: Int!) {
        allOcgTPatients(
            condition: {clinicianId: $clinicianId}
            filter: {email: {isNull: false}}
        ) { nodes { id name } }
    }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function SelectPatientDialog({ activityId, clinicianId, openDialog, setOpenDialog, assignmentsCreated }) {
    const navigate = useNavigate();
    const [openDatesDialog, setOpenDatesDialog] = React.useState(false);
    const [patientId, setPatientId] = React.useState('');
    const { loading, error, data } = useQuery(GET_PATIENTS, {
        variables: { clinicianId },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    const gqlPatients = data?.allOcgTPatients;
    function assignToPatient(e) {
        e.stopPropagation();
        setOpenDialog(false);
        setTimeout(() => {
            setOpenDatesDialog(true);
        }, "400");
    }
    const handleClose = () => {
        setOpenDialog(false);
    };
    React.useEffect(() => {
        if (error?.networkError?.statusCode === 401) {
            navigate('/login', { state: { returnToPage: true }});
        }
    }, [error, navigate]);
    return (
        <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="select patient"
            PaperProps={{ sx: { width: "300px", height: "190px" } }}
        >
            <DialogTitle style={{padding:'16px 24px 0 24px'}}>Assign Activity to Patient</DialogTitle>
            <DialogContent style={{paddingTop:'1.5rem'}}>
                <TextField select label='Patient' color="primary" style={{width:'100%'}}
                    value={patientId} onChange={(e) => setPatientId(e.target.value)}>
                        {gqlPatients?.nodes.map((patient) => (
                            <MenuItem key={patient.id} value={patient.id}>{patient.name}</MenuItem>
                        ))} 
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" size="small" disableElevation onClick={handleClose}>Cancel</Button>
                <Button variant="contained" size="small" disableElevation onClick={(e) => assignToPatient(e)} disabled={!patientId}>Assign</Button>
            </DialogActions>
            <AssignActivityDatesDialog activityId={activityId} clinicianId={clinicianId} patientId={patientId}
                openDialog={openDatesDialog} setOpenDialog={setOpenDatesDialog} assignmentsCreated={assignmentsCreated} />
        </Dialog>
    );
}