import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function ActivityTypeFilter({ activityType, changeActivityType }) {
    return (
        <ToggleButtonGroup color="primary" value={activityType} exclusive size='small'
            onChange={changeActivityType} aria-label="activity type filter">
            <ToggleButton value={0}>All Types</ToggleButton>
            <ToggleButton value={1}>Psychoeducation</ToggleButton>
            <ToggleButton value={2}>Exposure</ToggleButton>
            <ToggleButton value={3}>Assessment</ToggleButton>
            <ToggleButton value={4}>Mindfulness/Relaxation</ToggleButton>
            <ToggleButton value={5}>Reinforcers</ToggleButton>
        </ToggleButtonGroup>
    )
}