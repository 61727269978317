import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { TextField, Button, Alert } from '@mui/material';
// import { useTheme } from '@mui/styles';
import { domain } from '../App';
import { validateEmail, validatePassword } from '../scripts/helpers';
import db from '../scripts/db';

const gridStyles = {
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center'
};
const textFieldStyles = {
    margin:'0.5rem auto',
    width:'205px'
};

export default function Login() {
    // const theme = useTheme();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [view, setView] = React.useState('sign-in');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [validEmail, setValidEmail] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [validPassword, setValidPassword] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [hCaptcha, setHCaptcha] = React.useState(null);
    const [iAmHuman, setIAmHuman] = React.useState(true); //change me to false when activating hcaptcha again
    const [errorText, setErrorText] = React.useState('');
    const [buttonPressedOnce, setButtonPressedOnce] = React.useState(false);
    const [emailSent, setEmailSent] = React.useState(false);

    function localSigninError() {
        if (!email) {
            setErrorText('Please enter an email');
        }
        else if (!password) {
            setErrorText('Please enter a password');
        }
        else if (!validEmail) {
            setErrorText('Please enter a valid email');
        }
        else if (!validPassword) {
            setErrorText('Please enter a valid password');
        }
        else if (!iAmHuman) {
            setErrorText('Please complete captcha');
        }
    }
    function localSignupError() {
        if (!email) {
            setErrorText('Please enter an email');
        }
        else if (!password) {
            setErrorText('Please enter a password');
        }
        else if (password !== confirmPassword) {
            setErrorText('Passwords do not match');
        }
        else if (!name) {
            setErrorText('Please enter a name');
        }
        else if (!validEmail) {
            setErrorText('Please enter a valid email');
        }
        else if (!validPassword) {
            setErrorText('Please enter a valid password');
        }
        else if (!iAmHuman) {
            setErrorText('Please complete captcha');
        }
    }
    function localRequestPwResetError() {
        if (!email) {
            setErrorText('Please enter an email');
        }
        else if (!validEmail) {
            setErrorText('Please enter a valid email');
        }
        else if (!iAmHuman) {
            setErrorText('Please complete captcha');
        }
    }
    async function handleServerResponse(response) {
        if (response.status !== 200) { 
            const error = await response.text();
            setErrorText(error);
            return;
        }
        
        const payload = await response.json();
        // db.userBooks.bulkPut(payload.userBooks);
        // delete payload.userBooks;

        // if (payload.users) {
        //     db.users.bulkPut(payload.users);
        //     delete payload.users;
        // }
        
        db.user.put({ key: 1, loggedIn: true, ...payload });

        if (state?.returnToPage === true) navigate(-1);
        else navigate('/');
    }
    async function signin() {
        setButtonPressedOnce(true);
        if (!email || !password || !validEmail || !validPassword || !iAmHuman) {
            localSigninError();
            return;
        }
        const response = await fetch(`${domain}/auth/clinician/signin`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ email, password, hCaptcha })
        });
        await handleServerResponse(response);
    }
    async function signup() {
        setButtonPressedOnce(true);
        if (!name || !email || !password || password !== confirmPassword || !validEmail || !validPassword || !iAmHuman) {
            localSignupError();
            return;
        }
        const response = await fetch(`${domain}/auth/clinician/signup`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ name, email, password, hCaptcha })
        });
        await handleServerResponse(response);
    }
    async function requestResetPassword() {
        setButtonPressedOnce(true);
        if (!email || !validEmail || !iAmHuman) {
            localRequestPwResetError();
            return;
        }
        const response = await fetch(`${domain}/auth/clinician/request-reset-password`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ email, hCaptcha })
        });
        const data = await response.text();
        if (data === 'Success') {
            setEmailSent(true);
            setTimeout(() => {
                setEmailSent(false);
                setView('sign-in');
            }, 5000);
        } else {
            setErrorText(data.error);
        }
    }
    function hCaptchaSuccess(token, ekey) {
        setHCaptcha(token);
        setIAmHuman(true);
    }
    function changeName(e) {
        setErrorText('');
        setName(e.target.value);
    }
    function changeEmail(e) {
        setErrorText('');
        setEmail(e.target.value);
        setValidEmail(validateEmail(e.target.value));
    }
    function changePassword(e) {
        setErrorText('');
        setPassword(e.target.value);
        setValidPassword(validatePassword(e.target.value));
    }
    function changeConfirmPassword(e) {
        setErrorText('');
        setConfirmPassword(e.target.value);
    }
    function changeView(newView) {
        setButtonPressedOnce(false);
        setErrorText('');
        setView(newView);
    }
    function renderSignInForm() {
        if (view === 'sign-in') {
            return (
                <React.Fragment>
                    <TextField label="Email" variant="outlined" size="small" name="email" type="email"
                        error={(!email || !validEmail) && buttonPressedOnce} onChange={changeEmail}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'email', autoComplete: "username" }} />
                    <TextField label="Password" variant="outlined" size="small" name="password" type="password" 
                        error={(!password || !validPassword) && buttonPressedOnce} onChange={changePassword}
                        helperText={errorText}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'password', autoComplete: "current-password" }} />
                    <div style={{ width: '302px', height: '76px', borderRadius: '4px', overflow: 'hidden', margin: '0.5rem auto' }}>
                        {/* <HCaptcha
                            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                            reCaptchaCompat={false}
                            theme={theme.palette.mode}
                            onVerify={(token,ekey) => hCaptchaSuccess(token, ekey)}
                        /> */}
                    </div>
                    <Button variant="contained" size="small" style={{width:'205px', margin: '8px auto 16px auto'}} onClick={signin}
                        >Sign in</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} onClick={() => changeView('sign-up')}>Sign up</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} onClick={() => changeView('forgot-password')}>Forgot password</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} 
                        onClick={() => window.open(`${process.env.PUBLIC_URL}/VBI_PrivacyPolicy.pdf`, '_blank')}>Privacy Policy</Button>
                </React.Fragment>
            );
        }
        else if (view === 'sign-up') {
            return (
                <React.Fragment>
                    <TextField label="Email" variant="outlined" size="small" name="email" type="email" 
                        error={(!email || !validEmail) && buttonPressedOnce} onChange={changeEmail}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'email', autoComplete: "username" }} required />
                    <TextField label="Password" variant="outlined" size="small" name="password" type="password" 
                        error={(!password || !validPassword) && buttonPressedOnce} onChange={changePassword}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'new password', autoComplete: "new-password" }} required />
                    <TextField label="Confirm Password" variant="outlined" size="small" name="password" type="password" 
                        error={(!confirmPassword || confirmPassword !== password) && buttonPressedOnce} onChange={changeConfirmPassword}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'new password', autoComplete: "new-password" }} required />
                    <TextField label="Name" variant="outlined" size="small" name="name" type="name" 
                        error={(!name) && buttonPressedOnce} onChange={changeName}
                        helperText={errorText}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'name', autoComplete: "name" }} required />
                    <Alert variant="outlined" severity="info" icon={false} style={{ margin:'0.5rem auto', width:'301px' }}>
                        Passwords must match, have a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
                    </Alert>
                    <div style={{ width: '302px', height: '76px', borderRadius: '4px', overflow: 'hidden', margin: '0.5rem auto' }}>
                        {/* <HCaptcha
                            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                            reCaptchaCompat={false}
                            theme={theme.palette.mode}
                            onVerify={(token,ekey) => hCaptchaSuccess(token, ekey)}
                        /> */}
                    </div>
                    <Button variant="contained" size="small" style={{width:'205px', margin: '8px auto 16px auto'}} onClick={signup}>Sign up</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} onClick={() => changeView('sign-in')}>Sign in</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} 
                        onClick={() => window.open(`${process.env.PUBLIC_URL}/VBI_PrivacyPolicy.pdf`, '_blank')}>Privacy Policy</Button>
                </React.Fragment>
            );
        }
        else if (view === 'forgot-password') {
            return (
                <React.Fragment>
                    <TextField label="Email" variant="outlined" size="small" type="email" 
                        error={(!email || !validEmail) && buttonPressedOnce}  onChange={changeEmail}
                        helperText={emailSent ? 'Email sent' : errorText}
                        style={textFieldStyles} inputProps={{ 'aria-label': 'email', autoComplete: "username" }} required />
                    <div style={{ width: '302px', height: '76px', borderRadius: '4px', overflow: 'hidden', margin: '0.5rem auto' }}>
                        {/* <HCaptcha
                            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                            reCaptchaCompat={false}
                            theme={theme.palette.mode}
                            onVerify={(token,ekey) => hCaptchaSuccess(token, ekey)}
                        /> */}
                    </div>
                    <Button variant="contained" size="small" style={{width:'205px', margin: '8px auto 16px auto'}} onClick={requestResetPassword}>Send Email</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} onClick={() => changeView('sign-in')}>Sign in</Button>
                    <Button variant="text" size="small" style={{margin:'auto'}} onClick={() => changeView('sign-up')}>Sign up</Button>
                </React.Fragment>
            );
        }
    }
    React.useEffect(() => {
        db.appbar.put({ key: 1, title: 'Account' });
    }, []);
    return ( 
        <div style={gridStyles}>
            <h1>OC-GO</h1>
            {renderSignInForm()}
            <p style={{backgroundColor: '#ffc107c9', padding: '1rem', borderRadius: '5px', textAlign: 'left', marginTop:'2rem'}}>Please note that we are moving the protype OC-Go.org site to this site in order to facilitate increased users and scale subsequent to community interest and positive RCT results <a href="https://doi.org/10.1016/j.beth.2023.07.005" target="_blank">(https://doi.org/10.1016/j.beth.2023.07.005)</a>. The current platform is under construction with an anticipated launch in September.  Accordingly, although some features may be functional, it is not ready for clinical use or perusal and the library of interactive, searchable, and assignable exposures has not been imported yet from OC-Go.org. Please click the “Interested” link at the top of the page to be notified when this site is operational. Thank you and thank you for clinical practice helping to alleviate suffering with science-based intervention.  Best, OC-Go Development Team.</p>
        </div>
    );
}