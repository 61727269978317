import * as React from 'react';
import { FormLabel, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';

const textFieldStyles = {
    margin: '1rem 0.5rem 0 0.5rem'
};

export default function Timer({ timer, setTimer, timerUnit, setTimerUnit, disabled, setDisabled, saving }) {
    return (
        <div style={{marginTop:'2rem'}}>
            <FormLabel style={textFieldStyles}>Timer (automatically advances patient to next step)</FormLabel>
            <RadioGroup style={textFieldStyles} row aria-labelledby="timer" value={disabled} onChange={(e, newDisabled) => setDisabled(newDisabled)}>
                <FormControlLabel value="true" control={<Radio />} label="No Timer" disabled={saving} />
                <FormControlLabel value="visible to patient" control={<Radio />} label="Timer countdown visible to patient" disabled={saving} />
                <FormControlLabel value="hidden from patient" control={<Radio />} label="Timer countdown hidden from patient" disabled={saving} />
            </RadioGroup>
            <TextField style={textFieldStyles} label="Timer" color="primary" type="number" value={timer || 0} 
                onChange={(e) => setTimer(e.target.value)} disabled={disabled === 'true' || saving} />
            <TextField style={{...textFieldStyles, width: '150px'}} select label='Timer Unit' color="primary" disabled={disabled === 'true' || saving}
                value={timerUnit} onChange={(e) => setTimerUnit(e.target.value)}>
                    <MenuItem value="seconds">Seconds</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
            </TextField>
        </div>
    );    
}