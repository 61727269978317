import * as React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { useMutation, gql } from '@apollo/client';
import { IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import db from '../../scripts/db';

const CREATE_FAVORITE = gql`
   mutation createFavorite($clinicianId: Int!, $activityId: UUID!) {
        createOcgTFavorite(input: {ocgTFavorite: {clinicianId: $clinicianId, activityId: $activityId}}) { 
            ocgTFavorite { id }
        }
    }
`;
const DELETE_FAVORITE = gql`
    mutation MyMutation($id: Int!) {
        deleteOcgTFavoriteById(input: {id: $id}) {
            ocgTFavorite { id }
        }
    }
`;

export default function FavoriteButton({ activityId, favoritesId, style }) {
    const clinician = useLiveQuery(() => db.user.limit(1).toArray())?.[0];
    const clinicianId = clinician?.id;
    const [id, setId] = React.useState(favoritesId);
    const [createFavorite] = useMutation(CREATE_FAVORITE);
    const [deleteFavorite] = useMutation(DELETE_FAVORITE);
    function preventPropagationAndToggleFav(e) {
        e.stopPropagation();
        if (id) {
            deleteFavorite({ variables: { id }}).then(() => {
                setId(null);
            });
        }
        else {
            createFavorite({ variables: { clinicianId, activityId }}).then((res) => {
                const newId = res?.data?.createOcgTFavorite?.ocgTFavorite?.id;
                setId(newId);
            });
        }
    }
    const iconButtonStyles = {
        margin: style?.margin || '0.45rem 1rem 0 0',
        height: '40px'
    };
    // if (style) iconButtonStyles.margin = style?.margin;
    return (
        <IconButton onClick={preventPropagationAndToggleFav} style={iconButtonStyles} aria-label="favorite">
            { id ? <FavoriteIcon style={{color:'#e91e63'}} /> : <FavoriteBorderIcon /> }
        </IconButton>
    );    
}