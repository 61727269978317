let stream = null;
let mediaRecorder = null;
async function startStreaming(includeAudio = false) {
    const constraints = {
        video: { width: 1280, height: 720 }
    };

    if (includeAudio) {
        constraints.audio = { echoCancellation: { exact: true } };
    }

    stream = await navigator.mediaDevices.getUserMedia(constraints);
    return stream;
}
async function stopStreaming() {
    if (stream !== null) {
        (await stream)?.getTracks().forEach(function(track) {
            if (track.readyState === 'live') {
                track.stop();
            }
        });
        stream = null;
    }
}
function getSupportedMimeTypes() {
    const possibleTypes = [
        // 'video/webm;codecs=av1,opus',
        'video/webm;codecs=vp9,opus',
        'video/webm;codecs=vp8,opus',
        'video/webm;codecs=h264,opus',
        // 'video/mp4;codecs=h264,aac'
        'video/mp4',
    ];
    return possibleTypes.filter(mimeType => {
        return MediaRecorder.isTypeSupported(mimeType);
    });
}
function startRecording(handleDataAvailable) {
    const mimeTypes = getSupportedMimeTypes();
    mediaRecorder = new MediaRecorder(stream, {
        mimeType: mimeTypes[0]
    });
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();

}
function stopRecoring() {
    if (mediaRecorder) {
        mediaRecorder.stop();
        mediaRecorder = null;
    }
}
export { startStreaming, stopStreaming, startRecording, stopRecoring };