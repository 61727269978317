import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useMutation, gql } from '@apollo/client';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
function togglePublishedMutation(id, published) {
    const mutation = {
        mutation: {
            updateOcgTActivityById: {
                __args: {
                    input: {
                        ocgTActivityPatch: { published: !published }, id
                    }
                },
                ocgTActivity: {
                    published: true
                }
            }
        }
    };
    const gqlString = jsonToGraphQLQuery(mutation, { pretty: true });
    return gql`${gqlString}`;
}

export default function PublishButton({ activity, clinician, style }) {
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [published, setPublished] = React.useState(activity.published);
    const mutation = togglePublishedMutation(activity.id, published);
    const twoOrMoreTags = activity?.ocgTTagsByActivityId?.totalCount >= 2;
    function save() {
        togglePublished();
        setOpenDialog(false);
    }
    const [togglePublished, {}] = useMutation(mutation, {
        onCompleted: (data) => {
            setPublished(data?.updateOcgTActivityById?.ocgTActivity?.published);
        }
    });
    return (
        <React.Fragment>
            <Button variant="contained" size="small" color={published === true ? 'success' : 'primary'} disableElevation
                disabled={activity.clinicianId !== clinician?.id && clinician?.role !== 'editor' } style={style} 
                onClick={published === false ? () => setOpenDialog(true) : togglePublished}>
                    {published === true ? 'Published' : 'Publish'}
            </Button>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenDialog(false)}
                aria-describedby="select patient"
                PaperProps={{ sx: { width: "300px", height: "260px" } }}
            >
                <DialogTitle style={{padding:'16px 24px 0 24px'}}>Publish Activity</DialogTitle>
                <DialogContent style={{paddingTop:'1.5rem'}}>
                    { twoOrMoreTags ? 'Ready to Publish?' : 
                    'Add at least two search terms so people can find your activity (the more you add, the easier people will find it)' }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" size="small" style={{height:'100%'}} disableElevation onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button variant="contained" size="small" disableElevation onClick={twoOrMoreTags ? save : ()=>navigate(`/activities/form/${activity?.id}`)}>
                        { twoOrMoreTags ? 'Publish: I attest there is no PHI in this activity template' : 'Add Search Terms' }
                    </Button>
                </DialogActions>
            </Dialog>

         </React.Fragment>
    );    
}