import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function AssignmentTypeFilter({ assignmentType, changeAssignmentType }) {
    return (
        <ToggleButtonGroup color="primary" value={assignmentType} exclusive size='small'
            onChange={changeAssignmentType} aria-label="assignment type filter">
            <ToggleButton value={"Public Activity Library"}>Public Activity Library</ToggleButton>
            <ToggleButton value={"My Activities"}>My Activities</ToggleButton>
            <ToggleButton value={"My Favorites"}>My Favorites</ToggleButton>
        </ToggleButtonGroup>
    )
}