import React from 'react';

function getYouTubeVideoId(url) {
    let videoId = '';
  
    if (url.includes('watch?v=')) {
      const urlParams = new URLSearchParams(new URL(url).search);
      videoId = urlParams.get('v');
    } else {
      const urlParts = url.split('/');
      videoId = urlParts[urlParts.length - 1];
    }
  
    return videoId;
}

const YouTubePlayer = ({ url, width, height }) => {
  return (
    <div>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${getYouTubeVideoId(url)}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        credentialless="true"
      ></iframe>
    </div>
  );
};

export default YouTubePlayer;