import * as React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Autocomplete, Chip, TextField } from '@mui/material';

const GET_OPTIONS = gql`
    query GetOptions($pSearchText: String!, $pActivityType: String, $pClinicianId: Int, $pPublished: Boolean, $pFav: Boolean) {
        getActivityTagNames(pSearchText: $pSearchText, pActivityType: $pActivityType, pClinicianId: $pClinicianId, 
            pPublished: $pPublished, pFav: $pFav, first: 9) {
                nodes {
                    activityType
                    clinicianId
                    name
                    published
                    source,
                    isFavorite
                }
        }
    }
`;
export default function SearchBar({searchText, setSearchText, setSearchTextArr, activityType, clinician_id, published, fav}) {
    const [options, setOptions] = React.useState([]);
    const getOptions = useQuery(GET_OPTIONS, {
        variables: { 
            pSearchText: searchText,
            ...(activityType !== 'All Types') && { pActivityType: activityType },
            ...(clinician_id) && { pClinicianId: clinician_id },
            ...(published) && { pPublished: published },
            ...(fav) && { pFav: fav }
        },
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });
    function clear(e, value, reason) {
        if (reason === 'clear' || reason === 'reset') {
            setSearchText('');
        }
    }
    function displayChip(source) {
        if (source === 'tag') {
            return <Chip size="small" label='Search Term' style={{borderRadius: '4px',  position: 'absolute', right: '1rem', backgroundColor: '#B39DDB'}} />
        }
        else if (source === 'activity') {
            return <Chip size="small" label='Activity' style={{borderRadius: '4px',  position: 'absolute', right: '1rem', backgroundColor: '#EF9A9A'}} />
        }
        else if (source === 'clinician') {
            return <Chip size="small" label='Clinician' style={{borderRadius: '4px',  position: 'absolute', right: '1rem', backgroundColor: '#ffd54f'}} />
        }
    }
    React.useEffect(() => {
        setOptions(getOptions?.data?.getActivityTagNames?.nodes ?? [])
    }, [getOptions]);
    return (
        <Autocomplete
            style={{width: '100%', margin:'1rem 0'}}
            multiple
            options={options}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <li key={option.name} {...props}>
                    <span>{option.name}</span>
                    {displayChip(option.source)}
                </li>
            )}
            freeSolo
            onChange={(e, newTags) => {
                const newTagsArr = newTags.map(tag => (typeof tag === 'string' ? tag : tag.name));
                setSearchTextArr(newTagsArr);
            }}
            onInputChange={clear}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={option.name || option} {...getTagProps({ index })} style={{borderRadius: '4px'}} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{width:'100%'}}
                    variant="outlined"
                    placeholder="Search..."
                    onChange={e => setSearchText(e.target.value)}
                />
            )}
        />
    );
}
