import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';
import mime from 'mime';
const ffmpeg = new FFmpeg();
const { signal } = new AbortController();

async function loadffmpeg(multiThread = '') {
    try {
        // having issues with multiThread (no error thrown) on production not getting passed .exec command, so disabling for now
        // use multi thread if cross origin isolated otherwise use single thread
        // const multiThread = window.crossOriginIsolated === true ? '-mt' : '';
        // const multiThread = '';
        const baseURL = `https://unpkg.com/@ffmpeg/core${multiThread}@0.12.6/dist/umd`;
        // Listen to progress event instead of log.
        ffmpeg.on("progress", ({ progress, time }) => {
            console.log(`progess * 100: ${progress * 100} %`);
            console.log(`time: ${time}`);
            // messageRef.current.innerHTML = `${progress * 100} %`;
        });
        ffmpeg.on("log", ({ type, message }) => {
            console.log(`log type: ${type}`);
            console.log(`log message: ${message}`);
            // messageRef.current.innerHTML = `${progress * 100} %`;
        });
        // toBlobURL is used to bypass CORS issue, urls with the same domain can be used directly.
        const coreURL = await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript");
        const wasmURL = await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm");
        // Load workerURL only if multiThread is not empty, otherwise set it to null
        const workerURL = multiThread ? await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, "text/javascript") : null;
        await ffmpeg.load({ coreURL, wasmURL, workerURL });

        return true;
    } catch(e) {
        console.warn(e);
        return false;
    }
}
window.loadffmpegSingleThread = async () => {
    const bool = await loadffmpeg('');
    if (bool === false) console.log('loading error');
    else console.log('ffmpegSingleThread loaded');
}
window.loadffmpegMultiThread = async () => {
    const bool = await loadffmpeg('-mt');
    if (bool === false) console.log('loading error');
    else console.log('ffmpegMultiThread loaded');
}

// window.ffmpegExecArr = ['-i', `input.webm`, '-s', 'hd480', 'output.mp4'];
window.ffmpegExecArr = ['-i', 'input.webm', '-preset', 'ultrafast', '-vf', 'scale=trunc(iw/10)*2:trunc(ih/10)*2', 'output.mp4'];
async function transcode(videoBlob) {
    try {
        console.log('inside transcode function');
        const mimeType = videoBlob.type;
        const extension = mime.getExtension(mimeType);
        console.log(`ffmpeg.writeFile input.${extension}`);
        await ffmpeg.writeFile(`input.${extension}`, await fetchFile(videoBlob));
        console.log('finished ffmpeg.writeFile');
        console.log(`ffmpeg ${window.ffmpegExecArr.join(' ')}`);
        // await ffmpeg.exec(['-i', `input.${extension}`, 'output.mp4'], undefined, { signal }).catch(err => {
        //     if (err.name === "AbortError") {
        //       console.log(err.message) // "`Message # ID was aborted`"
        //     }
        //     console.log(err)
        // });
        await ffmpeg.exec(window.ffmpegExecArr, undefined, { signal }).catch(err => {
            if (err.name === "AbortError") {
              console.log(err.message) // "`Message # ID was aborted`"
            }
            console.log(err)
        });
        console.log('finished ffmpeg.exec');
        const data = await ffmpeg.readFile('output.mp4');
        console.log('finished ffmpeg.readFile');
        const blob = new Blob([data.buffer], {type: 'video/mp4'});
        console.log('finished creating Blob');
        return blob;
    }
    catch(e) {
        console.log('transcode error');
        console.warn(e);
    }
}

export { loadffmpeg, transcode };