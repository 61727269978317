import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextField, Button, Alert } from '@mui/material';
// import { useTheme } from '@mui/styles';
import { domain } from '../App';
import { validatePassword } from '../scripts/helpers';

const gridStyles = {
    display: 'grid',
    justifyContent: 'center',
    textAlign: 'center'
};
const textFieldStyles = {
    margin:'0.5rem auto',
    width:'205px'
};

export default function ResetPassword() {
    // const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const [password, setPassword] = React.useState('');
    const [validPassword, setValidPassword] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [hCaptcha, setHCaptcha] = React.useState(null);
    // const [hCaptcha, setHCaptcha] = React.useState('fake');
    // const [iAmHuman, setIAmHuman] = React.useState(false);
    const [iAmHuman, setIAmHuman] = React.useState(true);
    const [errorText, setErrorText] = React.useState('');

    async function resetPassword() {
        if (!email || !token || !password || password !== confirmPassword || !validPassword || !iAmHuman) return;
        const response = await fetch(`${domain}/auth/clinician/reset-password`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }, 
            body: JSON.stringify({ email, password, hCaptcha, token })
        });
        const data = await response.text();
        if (data === 'Success') {
            navigate('/login');
        } else {
            setErrorText(data.error);
        }
    }
    // function hCaptchaSuccess(token, ekey) {
    //     setHCaptcha(token);
    //     setIAmHuman(true);
    // }
    function changePassword(e) {
        setErrorText('');
        setPassword(e.target.value);
        setValidPassword(validatePassword(e.target.value));
    }
    function changeConfirmPassword(e) {
        setErrorText('');
        setConfirmPassword(e.target.value);
    }
    return ( 
        <div style={gridStyles}>
            <h2>Create a new Password</h2>
            <TextField label="Email" variant="outlined" size="small" name="email" type="email" value={email} disabled
                style={textFieldStyles} inputProps={{ 'aria-label': 'email', autoComplete: "username" }} required />
            <TextField label="Password" variant="outlined" size="small" name="password" type="password" 
                error={errorText || (password && !validPassword)} onChange={changePassword}
                style={textFieldStyles} inputProps={{ 'aria-label': 'new password', autoComplete: "new-password" }} required />
            <TextField label="Confirm Password" variant="outlined" size="small" name="password" type="password" 
                error={errorText || (confirmPassword && confirmPassword !== password)} onChange={changeConfirmPassword}
                helperText={errorText}
                style={textFieldStyles} inputProps={{ 'aria-label': 'new password', autoComplete: "new-password" }} required />
            <Alert variant="outlined" severity="info" icon={false} style={{ margin:'0.5rem auto', width:'301px' }}>
                Passwords must match, have a minimum of eight characters, at least one uppercase letter, one lowercase letter, one number and one special character
            </Alert>
            {/* <div style={{ width: '302px', height: '76px', borderRadius: '4px', overflow: 'hidden', margin: '0.5rem auto' }}>
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                    reCaptchaCompat={false}
                    theme={theme.palette.mode}
                    onVerify={(token,ekey) => hCaptchaSuccess(token, ekey)}
                />
            </div> */}
            <Button variant="contained" size="small" style={{width:'205px', margin: '8px auto 16px auto'}} onClick={resetPassword}
                disabled={errorText || !email || !token || !validPassword || confirmPassword !== password || !iAmHuman ? true : false}>Change Password</Button>
        </div>
    );
}